import { useEffect, useRef, useState } from "react";
import { JobProfessionLists } from "../../utils/services/jobs.service";
import { useSearchParams } from "react-router-dom";

const ProfessionSpecialitySearch = ({ refreshContent, saveClickRequired, searchPayload }: any) => {
    const [dropDownOpen, setdropDownOpen] = useState(false)
    const [ProfessionList, setProfessionListNewNew] = useState([]);
    const [secondLevelActive, setSecondLevelActive] = useState(false)
    const [thirdLevelActive, setthirdLevelActive] = useState(false);
    const [deleteClicked, setdeleteClicked] = useState(false);
    const [selectedSpecialityList, setselectedSpecialityList] = useState<any>(null);
    const [Ids, setIds] = useState([]);
    const [mergeReq, setMergeReq] = useState(false)
    const ref = useRef(null);
    const [RefreshSelectSection, setRefreshSelectSection] = useState(false)

    useEffect(() => {
        setMergeReq(true)
        setIds(searchPayload?.specialtyIds)
    }, [searchPayload?.specialtyIds])

    useEffect(() => {
        JobProfessionLists().then((res: any) => {
            res = res?.map((data: any) => {
                return {
                    ...data,
                    isOpen: false,
                    license: data?.license?.map((inData: any) => {
                        return {
                            ...inData,
                            isOpen: false,
                            specialties: inData?.specialties?.map((childData: any) => {
                                return {
                                    ...childData,
                                }
                            })
                        }
                    })
                }
            });
            setProfessionListNewNew(res)
        }).catch(e => console.error(e));
    }, [])

    useEffect(() => {
        if (mergeReq && ProfessionList?.length) {
            setMergeReq(false)
            let joinIds = Ids?.map((dt: any) => Number(dt));

            if (!joinIds?.length) {
                setSecondLevelActive(false);
                setthirdLevelActive(false);
                setselectedSpecialityList([])
            }
            let newArray = JSON.parse(JSON.stringify(ProfessionList));
            newArray = newArray?.map((data: any, ind: number) => {
                return {
                    ...data,
                    license: data?.license?.map((inData: any, inIndex: number) => {
                        return {
                            ...inData,
                            specialties: inData?.specialties?.map((childData: any) => {
                                if (joinIds?.includes(childData?.specialtyId) && !childData?.isSelected) {
                                    handleSelectionUpdate(childData)
                                    return {
                                        ...childData,
                                        isSelected: true
                                    }
                                }
                                return {
                                    ...childData,
                                    isSelected: false
                                }
                            })
                        }
                    })
                }
            })
            setProfessionListNewNew(newArray);
            setIds([]);
        }
    }, [Ids, ProfessionList, mergeReq])

    const changeOpenStateProfession = (professionIndex: number, toOpen: boolean = false) => {
        let newArray = JSON.parse(JSON.stringify(ProfessionList));
        newArray = newArray?.map((data: any, ind: number) => {
            return {
                ...data,
                isOpen: toOpen ? professionIndex == ind : false
            }
        })
        if (toOpen) {
            setSecondLevelActive(true)
        } else {
            setSecondLevelActive(false)
        }
        setProfessionListNewNew(newArray)
    }

    const changeOpenStatusLicense = (professionIndex: number, licenseIndex: number, toOpen: boolean = false) => {
        let newArray = JSON.parse(JSON.stringify(ProfessionList));
        newArray = newArray?.map((data: any, ind: number) => {
            return {
                ...data,
                license: data?.license?.map((inData: any, inIndex: number) => {
                    return {
                        ...inData,
                        isOpen: toOpen ? licenseIndex == inIndex : false
                    }
                })
            }
        })
        if (toOpen) {
            setthirdLevelActive(true)
        } else {
            setthirdLevelActive(false)
        }
        setProfessionListNewNew(newArray)
    }

    const checkBoxCheck = (specialityId: number, eve: any, refreshRequired = false) => {
        let newArray = JSON.parse(JSON.stringify(ProfessionList));
        newArray = newArray?.map((data: any, ind: number) => {
            return {
                ...data,
                license: data?.license?.map((inData: any, inIndex: number) => {
                    return {
                        ...inData,
                        specialties: inData?.specialties?.map((childData: any, childIndex: number) => {
                            if (specialityId == childData?.specialtyId) {
                                handleSelectionUpdate(childData)
                                return {
                                    ...childData,
                                    isSelected: eve.target.checked
                                }
                            }
                            return {
                                ...childData
                            }
                        })
                    }
                })
            }
        })
        setProfessionListNewNew(newArray);
        if (refreshRequired) {
            setdeleteClicked(true)
        }
    }

    const removeSelection = (_event: any, inex: number) => {
        checkBoxCheck(selectedSpecialityList[inex]?.specialtyId, { target: { checked: false } }, true);
    }

    const handleSelectionUpdate = (currentData: any) => {
        let newArray = JSON.parse(JSON.stringify(selectedSpecialityList)) || [];
        let indxes = newArray?.findIndex((p: any) => p?.specialtyId == currentData?.specialtyId);

        if (indxes > -1) {
            newArray?.splice(indxes, 1);
        } else {
            newArray.push({ specialtyId: currentData?.specialtyId, specialtyName: currentData?.specialtyName })
        }
        setselectedSpecialityList(newArray);
    }

    useEffect(() => {
        if (!saveClickRequired) {
            filterSearch()
        }
    }, [saveClickRequired, selectedSpecialityList])

    useEffect(() => {
        if (deleteClicked) {
            setdeleteClicked(false);
            filterSearch()
        }
    }, [deleteClicked, selectedSpecialityList])

    const handleDropDown = () => {
        setdropDownOpen(!dropDownOpen)
    }

    const filterSearch = () => {
        refreshContent(selectedSpecialityList)
    }

    useEffect(() => {
        if (RefreshSelectSection) {
            let newArray = JSON.parse(JSON.stringify(ProfessionList));
            let joinIds = selectedSpecialityList?.map((pp: any) => pp?.specialtyId);
            newArray = newArray?.map((data: any, ind: number) => {
                return {
                    ...data,
                    isOpen: false,
                    license: data?.license?.map((inData: any, inIndex: number) => {
                        return {
                            ...inData,
                            isOpen: false,
                            specialties: inData?.specialties?.map((childData: any) => {
                                if (joinIds?.includes(childData?.specialtyId)) {
                                    return {
                                        ...childData,
                                        isSelected: true
                                    }
                                }
                                return {
                                    ...childData,
                                    isSelected: false
                                }
                            })
                        }
                    })
                }
            })
            setProfessionListNewNew(newArray)
            setRefreshSelectSection(false)
            setSecondLevelActive(false);
            setthirdLevelActive(false);
        }
    }, [RefreshSelectSection])

    useEffect(() => {
        // Function to handle click events
        function handleClickOutside(event: any) {
            if (ref?.current && !(ref?.current as any)?.contains(event.target)) {
                // Click occurred outside the target div
                setdropDownOpen(false); // Close the dropdown or perform any action
                setRefreshSelectSection(true)
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    return (
        <>
            <div className="multilevelddlcontainer mb-2">
                <div className="dropdown">
                    <button className="dropbtn form-select form-select-lg" type="button" onClick={handleDropDown} >
                        {
                            !selectedSpecialityList?.length ? (
                                <span className="custom-ddl-title">Select specialties by profession</span>
                            ) : (
                                <>
                                    <div className="d-flex flex-wrap pt-1 p-2">
                                        <>
                                            {
                                                selectedSpecialityList?.filter((pp: any, inde: number) => inde < 4)?.map((pp: any, index: any) => (
                                                    <>
                                                        {
                                                            <div className="badge badge-pill bg-primary bg-opacity-25 text-dark me-2 fw-normal">
                                                                <span>{pp?.specialtyName}</span>
                                                                <a className="text-decoration-none ms-1" onClick={(e: any) => removeSelection(e, index)}  >
                                                                    <i className="fa fa-trash text-dark"></i>
                                                                </a>
                                                            </div>

                                                        }
                                                    </>
                                                ))
                                            }
                                            {
                                                selectedSpecialityList?.length > 4 && (
                                                    <a className="link-underline-primary">+ {selectedSpecialityList?.length - 4} more</a>
                                                )
                                            }
                                        </>
                                    </div>
                                </>
                            )
                        }
                    </button>
                    {
                        dropDownOpen && (
                            <div id="myDropdown" className="dropdown-content" ref={ref}>
                                <div className="custom-ddl-body">
                                    <ul className="custom-ddl-list custom-ddl-list-level1">
                                        {
                                            ProfessionList?.map((data: any, dataIndex: number) => (
                                                <li key={'mainlisting' + data?.professionId} >
                                                    <div className="custom-ddl-list-level1-content">
                                                        {
                                                            (!data?.isOpen && !secondLevelActive) && (
                                                                <div className="custom-ddl-list-label custom-ddl-list-level1-label" onClick={e => changeOpenStateProfession(dataIndex, true)} >{data?.professionName}
                                                                </div>
                                                            )
                                                        }
                                                        {
                                                            (data?.isOpen) && (
                                                                <ul className="custom-ddl-list custom-ddl-list-level2">
                                                                    {
                                                                        !thirdLevelActive && (
                                                                            <li className="lnkBackParentLvl1" key={'profession' + data.professionId} >
                                                                                <label htmlFor="" onClick={e => changeOpenStateProfession(dataIndex, false)}>
                                                                                    <i className="fa fa-chevron-left me-2"></i>
                                                                                    Back to Profession Type
                                                                                </label>
                                                                            </li>
                                                                        )
                                                                    }
                                                                    {
                                                                        data?.license?.map((inData: any, licenseIndex: number) => (
                                                                            (!inData?.isOpen && !thirdLevelActive) && (
                                                                                <li className="custom-ddl-list-label-inner" key={'listing' + inData?.licenseId} onClick={e => changeOpenStatusLicense(dataIndex, licenseIndex, true)} >{inData?.licenseName}</li>
                                                                            )
                                                                        ))
                                                                    }
                                                                    {
                                                                        data?.license?.map((inData: any, licenseIndex: number) => (
                                                                            (inData?.isOpen && thirdLevelActive) && (
                                                                                <>
                                                                                    <li key={'llistngs' + inData?.licenseId} >
                                                                                        <ul className="custom-ddl-list custom-ddl-list-level3">
                                                                                            <li className="lnkBackParentLvl2" key={'chekis' + licenseIndex} onClick={e => changeOpenStatusLicense(dataIndex, licenseIndex, false)}>
                                                                                                <label htmlFor="">
                                                                                                    <i className="fa fa-chevron-left me-2"></i>
                                                                                                    Back to {data?.professionName} professions
                                                                                                </label>
                                                                                            </li>
                                                                                            {
                                                                                                inData?.specialties?.map((lastData: any, lastIndex: number) => (
                                                                                                    <li key={'checkboxing' + lastData?.specialtyId} >
                                                                                                        <label>
                                                                                                            <input type="checkbox" className="custom-ddl-list-checkbox" checked={lastData?.isSelected} onChange={e => checkBoxCheck(lastData?.specialtyId, e)} /> {lastData?.specialtyName}
                                                                                                        </label>
                                                                                                    </li>
                                                                                                ))
                                                                                            }
                                                                                        </ul >
                                                                                    </li>
                                                                                </>
                                                                            )
                                                                        ))
                                                                    }
                                                                </ul>
                                                            )
                                                        }
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>


                                <div className="custom-ddl-list-footer">
                                    <button className="btn btn-primary" type="button" onClick={filterSearch} >Show Results</button>
                                </div>

                            </div >
                        )
                    }
                </div >
            </div >
        </>
    )
}

export default ProfessionSpecialitySearch;