import { useForm } from 'react-hook-form';
import mainlogo from '../../assets/images/Logo.png';
import { login } from '../../utils/services/auth.service';
import Loader from '../../components/loader/loader';
import { useEffect, useState } from 'react';
import { getAuthToken, setAuthToken } from '../../utils/services/localstorage.service';
import { useNavigate } from 'react-router-dom';
import Toast from '../../components/toaster/toaster';

const LoginComponent = () => {

    const [userLogged, setUserLogged] = useState(getAuthToken());
    const navigate = useNavigate();
    const [showToast, setshowToast] = useState(false);
    const [toasterMsg, setToastMsg] = useState('');
    const [toasterType, setToasterType] = useState('');

    useEffect(() => {
        if (userLogged) {
            navigate('/admin/listings')
        }
    }, [userLogged])

    const { register, handleSubmit, formState: { errors } } = useForm();

    const [isLoading, setLoading] = useState(false)
    const onSubmit = (data: any) => {
        const paylaod = {
            ...data
        }
        setLoading(true)
        login(paylaod).then((response: any) => {
            setAuthToken(response?.result?.token);
            setUserLogged(getAuthToken());
        }).catch(err => {
            setshowToast(true)
            setToastMsg(`Issue in authentication. Please verify credentials`)
            setToasterType(`error`)
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <>
            <Loader loading={isLoading} />
            {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
            <section className="admin-panel-login">
                <div className="admin-login-container">
                    <div className="admin-login-header">
                        <img src={mainlogo} className="img-fluid admin-login-header-image" alt="logo" onClick={e => navigate('/')} />
                    </div>
                    <div className="admin-login-body pt-4 pb-2">
                        <h4 className="text-center">Admin Panel</h4>
                        <p className="text-center">Control panel login</p>
                        <div className="container-fluid">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div>
                                    <label className="form-label" htmlFor="txtUsername">Username *:</label>
                                    <input type="text" className="form-control" id="txtUsername" {...register("username", { required: true })} />
                                    {errors.username && <span className="validationError">This field is required</span>}
                                </div>
                                <div>
                                    <label className="form-label" htmlFor="txtPassword">Password *:</label>
                                    <input type="password" className="form-control" id="txtPassword" {...register("password", { required: true })} />
                                    {errors.password && <span className="validationError">This field is required</span>}
                                </div>
                                <div className='mt-2'>
                                    <p className="text-center">
                                        <button type="submit" className="btn btn-dark py-2 px-5">Login</button>
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default LoginComponent;