import { NavLink, useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/images/Logo.png'
import { useEffect, useState } from 'react';
import LinkedInImage from '../../assets/images/icon_linkedin.svg'
import FacebookImage from '../../assets/images/icon_facebook.svg'
import { getAuthToken, removeStorage } from '../../utils/services/localstorage.service';

const Header = () => {

    const [userLogged] = useState(getAuthToken());
    const navigate = useNavigate();
    const [userSigned, setuserSigned] = useState(false)
    useEffect(() => {
        if (!userLogged) {
            setuserSigned(false);
            return;
        }
        setuserSigned(true)

    }, [userLogged]);

    const logout = () => {
        removeStorage();
        navigate('/admin/login')
    }

    return (

        <header className="header">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-8 col-sm-8 col-md-4">
                        <NavLink to='/'>
                            <img src={LogoImage} className="logo img-fluid ms-lg-4" alt="Logo" />
                        </NavLink>
                    </div>

                    <div className="col-4 col-sm-4 col-md-8">

                        <nav className="navbar navbar-expand-md navbar-dark">
                            <button className="navbar-toggler  ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav  ms-auto ">
                                    <li className="nav-item">
                                        <NavLink to='/jobs' className="nav-link" aria-current="page">
                                            Find jobs
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/about-us' className="nav-link" aria-current="page">
                                            About us
                                        </NavLink>
                                    </li>
                                    <li className="nav-item d-none">
                                        <a className="nav-link" >Apply</a>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/contact-us' className="nav-link" aria-current="page">
                                            Contact us
                                        </NavLink>
                                    </li>
                                    {
                                        userSigned ? (
                                            <li className='ms-lg-4 me-lg-4'>
                                                <div className='socialmedia-icons my-3 my-lg-1'>
                                                    <a title="Logout" href='javsacript:void(0);' onClick={logout} >
                                                        <span className='d-inline-block '>
                                                            <i className='fa fa-sign-out text-white'></i>
                                                        </span>
                                                        <span className='d-md-none text-white ms-2'>Logout</span>
                                                    </a>
                                                </div>
                                            </li>
                                        ) : (
                                            <li className='ms-lg-4 me-lg-4'>
                                                <div className="socialmedia-icons">
                                                    <a title="Linked In" href='https://www.linkedin.com/company/81897394/admin/feed/posts/' target='_blank'>
                                                        <img
                                                            src={LinkedInImage}
                                                            alt="Linked In"
                                                            className="socialmedia-icon-linkedIn"
                                                        />
                                                    </a>
                                                    <a title="facebook" className='ms-3' href='https://www.facebook.com/profile.php?id=61556540486997' target='_blank'>
                                                        <img
                                                            src={FacebookImage}
                                                            alt="facebook"
                                                            className="socialmedia-icon-facebook"
                                                        />
                                                    </a>
                                                </div>
                                            </li>
                                        )
                                    }
                                </ul>
                            </div>
                        </nav>
                        {/* <!--Main Nav ends--> */}
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;