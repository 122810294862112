import React, { Component, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, useLocation, useRoutes } from 'react-router-dom';
import Header from './components/common-views/header';
import Footer from './components/common-views/footer';
import Home from './pages/home';
import ContactUs from './pages/contact-us';
import AboutUs from './pages/about-us';
import { endPoints } from './utils/expoints.utils';
import JobListingView from './pages/job-listing-view';
import './assets/scss/child-theme.scss'
import JobDetailView from './pages/job-detail-view';
import ApplyOnJob from './pages/apply-job';
import LoginComponent from './pages/admin-section/login';
import AdminJobListing from './pages/admin-section/job-listing';
import CreateJob from './pages/admin-section/create-job';
import { getAuthToken } from './utils/services/localstorage.service';

function App() {

  const { pathname } = useLocation();
  const isLoggedIn = useState(getAuthToken())

  // const PrivateRoute = ({ component, ...rest }: any) => (
  //   <Route
  //     {...rest}
  //     render={(props: any) => (
  //       isLoggedIn ? <Component {...props} /> : <Redirect to="/admin/login" />
  //     )
  //     }
  //   />
  // );

  return (
    <>
      <div id="toast-container"></div>
      <div>
        {!pathname.includes('admin/login') && <Header />}
        <div>
          <div className="main-content-container">
            <Routes>
              <Route path={endPoints.HOME.path} Component={Home} />
              <Route path={endPoints.JOBLIST.path} Component={JobListingView} />
              <Route path={endPoints.SPECIFICJOB.path} element={<JobDetailView />} />
              <Route path={endPoints.APPLYONJOB.path} element={<ApplyOnJob />} />
              <Route path={endPoints.ADMINLOGIN.path} element={<LoginComponent />} />
              <Route path={endPoints.ADMINJOBLISITNG.path} element={<AdminJobListing />} />
              <Route path={endPoints.CREATEJOBPOSTING.path} element={<CreateJob />} />
              <Route path={endPoints.EDITJOBPOSTING.path} element={<CreateJob />} />
              <Route path={endPoints.ADMINJOBLISITNG.path} element={<AdminJobListing />} />
              <Route path="/admin/*" element={<Navigate to="/admin/login" replace />} />
              <Route path={endPoints.CONTACTUS.path} element={<ContactUs />} />
              <Route path={endPoints.ABOUTUS.path} element={<AboutUs />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
