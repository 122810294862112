import { useState, useEffect } from "react";
import { gettates } from "../../utils/services/lookup.service";
import { useForm } from "react-hook-form";

const LocationSelect = ({ locationChange, searchPayload }: any) => {
    const [stateData, setstateData] = useState<any[]>([]);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({});

    useEffect(() => {
        gettates().then((resp: any) => {
            setstateData(resp?.result)
        }).catch((e: any) => console.error(e))
    }, [])

    const selectionUpdated = (eve: any) => {
        let selectedValue = eve.target.value;
        locationChange(selectedValue)
    }

    useEffect(() => {
        if (stateData?.length) {
            setValue('location', searchPayload?.stateName)
        }
    }, [stateData, searchPayload?.stateName])

    return (
        <>
            <form>
                <select className="form-select form-select-lg" aria-label="Preferred Locations"
                    id="ddlPreferredLocations"  {...register("location")} onChange={e => selectionUpdated(e)}>
                    <option value="">Select a Location</option>
                    {stateData?.map((data: any, index: number) => (
                        <option key={data?.name} value={data?.name}> {data?.name}</option>
                    ))}
                </select>
            </form>
        </>
    )
}

export default LocationSelect;