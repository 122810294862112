import HomeAdv1 from '../../assets/images/home-advantage-1.png';
import HomeAdv2 from '../../assets/images/home-advantage-2.png';
import HomeAdv3 from '../../assets/images/home-advantage-3.png';
import HomeAdv4 from '../../assets/images/home-advantage-4.png';

const AdvantageDetail = () => {
    return (
        <section className="advantageSection py-5">
            <div className="container-fluid px-lg-5">
                <div className="row gx-lg-5">
                    <div className="col-12">
                        <h2 className="fw-bold mb-4">Advantages at Volto Healthcare</h2>
                    </div>
                    <div className="col-md-6 col-lg-3 advantageColumn mb-4 mb-lg-0">
                        <div className="advantageImageContainer advantageImageContainer1">
                            <img
                                src={HomeAdv1}
                                className="img-fluid"
                                alt="400 healthcare clients"
                            />
                            <div className="advantageText">
                                <span className="d-block text-size-2dot2 fw-bold">400+</span>
                                <span className="d-block text-size-1dot8">
                                    Healthcare
                                    <br />
                                    Clients
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 advantageColumn mb-4 mb-lg-0">
                        <div className="advantageImageContainer advantageImageContainer2">
                            <img
                                src={HomeAdv2}
                                className="img-fluid"
                                alt="Professionals
                        trust us"
                            />
                            <div className="advantageText">
                                <span className="d-block text-size-2dot2 fw-bold">1000+</span>
                                <span className="d-block text-size-1dot8">
                                    Professional
                                    <br />
                                    trust us
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 advantageColumn mb-4 mb-lg-0">
                        <div className="advantageImageContainer advantageImageContainer3">
                            <img
                                src={HomeAdv3}
                                className="img-fluid"
                                alt="Flexible work-Hours"
                            />
                            <div className="advantageText">
                                <span className="d-block text-size-1dot8">
                                    Flexible
                                    <br />
                                    work-Hours
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3 advantageColumn mb-4 mb-lg-0">
                        <div className="advantageImageContainer advantageImageContainer4">
                            <img
                                src={HomeAdv4}
                                className="img-fluid"
                                alt="Free Meditation Classes"
                            />
                            <div className="advantageText">
                                <span className="d-block text-size-1dot8">
                                    Free
                                    <br />
                                    Meditation
                                    <br />
                                    Classes
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AdvantageDetail