import { useEffect, useState } from "react";
import LocationSelect from "../sidefilters/location-select";
import { JobProfessionLists } from "../../utils/services/jobs.service";
import { jobtypes, ratePreference } from "../../utils/services/lookup.service";
import { useForm } from "react-hook-form";
import ProfessionSpecialitySearch from "./preofession-speciality-search";
import { useNavigate, useSearchParams } from "react-router-dom";

const SidebarSearch = ({ refreshSearch, locl, searchPayload }: any) => {

    const [innerStateChange, setInnerStateChange] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm();


    const [loc, setLoc] = useState(searchPayload?.stateName);
    const [specIds, setSpecIds] = useState(searchPayload?.specialtyIds)

    const locationChange = (content: any) => {
        let locations = `${content}`;
        setInnerStateChange(true)
        setLoc(locations)
    }

    const refreshContent = (paylaod: any) => {
        let specialtyIds = paylaod?.map((p: any) => p?.specialtyId)?.join(',');
        setInnerStateChange(true)
        setSpecIds(specialtyIds)
    }

    useEffect(() => {
        if (innerStateChange) {
            handleSearch()
        }
    }, [innerStateChange])


    const handleSearch = () => {
        if (innerStateChange) {
            setInnerStateChange(false);
            let newObje = { ...searchPayload };
            if (newObje?.stateName != loc) {
                newObje = { ...newObje, stateName: loc }
            }
            if (newObje?.specialtyIds?.toString() !== specIds?.toString()) {
                newObje = { ...newObje, specialtyIds: specIds?.toString()?.split(',')?.filter((p: any) => p) }
            }
            refreshSearch(newObje)
        }
    }

    const clearAllFilter = () => {
        if (loc == '' && !specIds?.length) {
            return;
        }
        setLoc('');
        setSpecIds([]);
        setInnerStateChange(true)
    }


    return (
        <>
            <div className="col-lg-3 col-xxl-2 filter-container">
                <div className="d-flex flex-wrap justify-content-between align-items-baseline p-2">
                    <h3>FILTERS</h3>
                    <a className="text-size-0dot85" onClick={clearAllFilter} >CLEAR ALL</a>
                </div>

                <div className="accordion" id="filterAccordions">
                    <form>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="locationHeading">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#locationCollapse" aria-expanded="true" aria-controls="locationCollapse"> Location
                                </button>
                            </h2>
                            <div id="locationCollapse" className="accordion-collapse collapse show" aria-labelledby="locationHeading">
                                <div className="accordion-body">
                                    <LocationSelect locationChange={(e: any) => locationChange(e)} searchPayload={searchPayload} />
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header" id="specialityProfesionHeading">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#specialityCollapse" aria-expanded="true" aria-controls="specialityCollapse"> Speciality of Profession
                                </button>
                            </h2>
                            <div id="specialityCollapse" className="accordion-collapse collapse show" aria-labelledby="specialityProfesionHeading">
                                <div className="accordion-body">
                                    <ProfessionSpecialitySearch refreshContent={(e: any) => refreshContent(e)} saveClickRequired={true} searchPayload={searchPayload} />
                                </div>
                            </div>
                        </div>


                        {/* <div className="accordion-item mt-2">
                            <h2 className="accordion-header" id="jobTypeHeading">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#jobTypeCollapse" aria-expanded="false" aria-controls="jobTypeCollapse"> Job Type
                                </button>
                            </h2>
                            <div id="jobTypeCollapse" className="accordion-collapse collapse show" aria-labelledby="jobTypeHeading">
                                <div className="accordion-body">
                                    <select className="form-select form-select-lg" aria-label="Select job type" id="ddlSelectjobtype">
                                        <option value="" selected disabled>Select job type</option>
                                        {jobtypesdata.map((data: any) => (
                                            <option key={'typeId' + data.typeId} value={data.typeId}>{data.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="accordion-item mt-2">
                            <h2 className="accor=dion-header" id="licenseHeading">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#licenseCollapse" aria-expanded="false" aria-controls="licenseCollapse">
                                    Profession/License </button>
                            </h2>
                            <div id="licenseCollapse" className="accordion-collapse collapse show" aria-labelledby="licenseHeading">
                                <div className="accordion-body">
                                    {AllProfessions?.map((data: any, index) => (
                                        <div key={index}>
                                            <input className="form-check-input ms-2" type="radio" value={data?.professionId} id={'radioFor' + data?.professionId}  {...register('professionId', { required: true })} onChange={e => professionChange(e)} />
                                            <label className="form-check-label" htmlFor={'radioFor' + data?.professionId}> {data?.professionName} </label >
                                        </div>
                                    ))}
                                    <select className="form-select form-select-lg" id="ddlLicense" {...register("licenseId", { required: false })} onChange={(e) => licenseChange(e)}>
                                        <option value="" selected disabled>Select</option>
                                        {joblicensesdata.map((data: any) => (
                                            <option key={'licesnse' + data.licenseId} value={data.licenseId}>{data.licenseName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="accordion-item mt-2">
                            <h2 className="accordion-header" id="specialtyHeading">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#specialtyCollapse" aria-expanded="false" aria-controls="specialtyCollapse">
                                    Specialty </button>
                            </h2>
                            <div id="specialtyCollapse" className="accordion-collapse collapse show"
                                aria-labelledby="specialtyHeading">
                                <div className="accordion-body">
                                    <select className="form-select form-select-lg" id="ddlSpecialty" {...register("specialtyId", { required: true })}>
                                        <option value="" selected disabled>Select</option>
                                        {specialtiesdata.map((data: any) => (
                                            <option key={'speciality' + data.specialtyId} value={data.specialtyId}>{data.specialtyName}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div> */}


                        {/* <div className="accordion-item mt-2">
                        <h2 className="accordion-header" id="rateHeading">
                            <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                data-bs-target="#rateCollapse" aria-expanded="false" aria-controls="rateCollapse"> Rate </button>
                        </h2>
                        <div id="rateCollapse" className="accordion-collapse collapse show" aria-labelledby="rateHeading">
                            <div className="accordion-body">
                                <select className="form-select form-select-lg" aria-label="Rate" id="ddlRate">
                                    <option selected>Rate Term</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                                <div className="slidecontainer mt-4">
                                    <input type="range" min="1" max="4000" step="1" value="1" className="slider" id="myRange" />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <label className="form-label text-start">$1</label>
                                    <label className="form-label text-end">$4000</label>
                                </div>
                                <label className="form-label d-block text-end"><strong className="text-primary">Minimum Rate:</strong>
                                    <span className="fw-normal" id="selectedRangeValue">$0</span></label>
                            </div>
                        </div>
                    </div> */}
                    </form>
                </div>


            </div >
        </>
    )
}

export default SidebarSearch;