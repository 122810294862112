import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbContent from "../../components/common-views/breadcrumb";
import { endPoints } from "../../utils/expoints.utils";
import { citiesOfState, gettates, jobtypes, ratePreference, workSchedules } from "../../utils/services/lookup.service";
import { JobProfessionLists, createNewJob, getJobDetail, updateJobDetail } from "../../utils/services/jobs.service";
import { useForm } from "react-hook-form";
import { MaxWorkingDaysInWeek, ScheduleAvailableTimings, ShiftPreferenceTypes, expirenceInYears } from "../../utils/constants/jobs.constants";
import { getAuthToken } from "../../utils/services/localstorage.service";
import Loader from "../../components/loader/loader";
import Toast from "../../components/toaster/toaster";
import HtmlEditor from "../../components/common-views/content-editor";

export enum ActiveSection {
    sectionJobDescription,
    sectionJobResponsibilities,
    sectionQualifications,
    sectionSkills,
    sectionEducationRequirements,
    sectionExperienceRequirements,
}
const CreateJob = () => {
    const { register, handleSubmit, formState: { errors }, watch, setValue, reset } = useForm({});
    const [userLogged] = useState(getAuthToken());

    useEffect(() => {
        if (!userLogged) {
            navigate('/admin/login')
        }
    }, [userLogged]);

    const params = useParams();
    const [jobId, setJobId] = useState(0);
    const [activeSection, setActiveSection] = useState(ActiveSection.sectionJobDescription);

    const [stateData, setstateData] = useState<any[]>([]);
    const [cityData, setcityData] = useState([]);
    const [jobtypesdata, setjobtypesdata] = useState([])
    const [rateType, setrateType] = useState([])
    const [AssignmentWorkSchedule, SetAssignmentWorkSchedule] = useState([])
    const [AllProfessions, SetProfessions] = useState<any[]>([])
    const [joblicensesdata, setjoblicensesdata] = useState<any[]>([])
    const [specialtiesdata, setspecialtiesdata] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)
    const [showToast, setshowToast] = useState(false);
    const [toasterMsg, setToastMsg] = useState('');
    const [toasterType, setToasterType] = useState('');
    const [activeJobDetail, setactiveJobDetail] = useState<any>({});
    const [jobDetailLoad, setJobDetailLoad] = useState(false);
    const [jbresp, setjbresp] = useState('');
    const [jbquality, setjbquality] = useState('');
    const [skill, setskill] = useState('');
    const [education, seteducation] = useState('');
    const [expirence, setexpirence] = useState('');
    const navigate = useNavigate();

    // DropDown related states

    const [city, setCity] = useState('');
    const [lics, setLics] = useState(null);
    const [spcs, setSpcs] = useState(null);


    useEffect(() => {
        Promise.all([gettates(), jobtypes(), ratePreference(), workSchedules(), JobProfessionLists()]).then((response: any) => {
            setstateData(response?.[0]?.result);
            setjobtypesdata(response?.[1])
            setrateType(response?.[2])
            SetAssignmentWorkSchedule(response?.[3])
            SetProfessions(response?.[4]);
        }).catch().finally(() => setJobDetailLoad(true))
    }, [])


    useEffect(() => {
        if (params.jobId) {
            setLoading(true)
            setJobId(Number(params.jobId))
        }
    }, [params.jobId])

    const scrollToPosition = (sectionName: string, currentActiveId: number) => {
        const sectionRef: any = document.querySelector(`#${sectionName}`)
        sectionRef?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (currentActiveId == activeSection) {
            return;
        }
        setActiveSection(currentActiveId)
    }


    useEffect(() => {
        if (Number(jobId) > 0) {
            getJobDetail(jobId).then((result: any) => {
                if (result.statusCode == 200) {
                    setactiveJobDetail(result?.result);
                    professionChange({ target: { value: result?.result?.professionId?.toString() } })
                    licenseChange({ target: { value: result?.result?.licenseId?.toString() } })
                    stateUpdated({ target: { value: result?.result?.stateName } });
                }
            }).catch(e => {
                console.error(e)
            })
        }
    }, [jobDetailLoad, jobId]);

    useEffect(() => {
        if (activeJobDetail?.jobId) {
            setLoading(false);
            patchFormContent()
        }
    }, [activeJobDetail]);

    useEffect(() => {
        if (lics) {
            setValue('license', activeJobDetail?.licenseId?.toString())
        }
    }, [lics])
    useEffect(() => {
        if (spcs) {
            setValue('specialty', activeJobDetail?.specialtyId?.toString())
        }
    }, [spcs])
    useEffect(() => {
        if (city) {
            setValue('City', activeJobDetail?.cityName)
        }
    }, [city])

    const professionChange = (e: any) => {
        const newVal = AllProfessions?.find((val: any) => val.professionId == e.target.value)
        if (!newVal) {
            return;
        }
        setjoblicensesdata(newVal?.license)
    }

    const licenseChange = (e: any) => {
        const neVal = joblicensesdata?.find((val: any) => val?.licenseId == e.target.value)
        if (!neVal) {
            return;
        }
        setspecialtiesdata(neVal?.specialties)
    }

    const stateUpdated = (e: any) => {
        setValue('City', null);
        let stateID = (stateData?.find((val: any) => val.name == e.target.value)?.stateId)
        if (!stateID) {
            return;
        }
        citiesOfState(stateID).then((resp: any) => {
            const city = resp?.result?.cities || []
            setcityData(city)
        }).catch((e: any) => console.error(e))
    }

    const contentUpdate = (content: any, keyName: string) => {
        setValue(keyName, content);
    }

    const patchFormContent = () => {
        if (!activeJobDetail?.jobId) {
            return;
        }

        setValue('jobTitle', activeJobDetail?.jobTitle)
        setValue('opening', activeJobDetail?.openings)
        setValue('State', activeJobDetail?.stateName)
        setValue('jobType', activeJobDetail?.typeId?.toString())
        setValue('startDate', `${activeJobDetail?.startDate?.split("T")?.[0]}`)
        setValue('experience', activeJobDetail?.experience)
        setValue('rateType', activeJobDetail?.rateId)
        setValue('Amount', activeJobDetail?.rateValue)
        setValue('AssignmentDuration', activeJobDetail?.assignmentDuration)
        setValue('assignmentLength', activeJobDetail?.assignmentWorkScheduleId)
        setValue('immediateJoining', activeJobDetail?.immediateJoining ? 'true' : 'false')
        setValue('jobresponsibilities', activeJobDetail?.responsibilities)
        setValue('qualifications', activeJobDetail?.qualifications)
        setValue('skills', activeJobDetail?.skills)
        setValue('educationrequirements', activeJobDetail?.educationRequirements)
        setValue('expirencerequirement', activeJobDetail?.experienceRequirements)

        let scheduleValues = activeJobDetail?.schedule?.split(" ");
        setValue('WorkingDays', scheduleValues?.[0]?.split('x')?.[0]?.toString())
        setValue('ShiftPrefernce', scheduleValues[2]?.toString())

        setValue('profession', activeJobDetail?.professionId?.toString())

        setjbresp(activeJobDetail?.responsibilities)
        setjbquality(activeJobDetail?.qualifications)
        setskill(activeJobDetail?.skills)
        seteducation(activeJobDetail?.educationrequirements)
        setexpirence(activeJobDetail?.expirencerequirement)

        setCity(activeJobDetail?.cityName)
        setLics(activeJobDetail?.licenseId?.toString());
        setSpcs(activeJobDetail?.specialtyId?.toString())

    }

    // const watchFields = watch(["StartTime", "EndTime"]);

    // const validateEndDate = (EndTime: any) => {
    //     const startDate = watchFields[0];
    //     return (EndTime >= startDate) || "End Time must be after State Time";
    // };

    const handleToastDetail = (toastContent: any) => {
        setshowToast(toastContent?.show)
        setToastMsg(toastContent?.msg)
        setToasterType(toastContent?.type)
    }


    const onSubmit = (data: any) => {
        const jobCreatePayload = {
            "jobId": jobId,
            "jobTitle": data?.jobTitle,
            "stateName": data?.State,
            "cityName": data?.City,
            "openings": Number(data?.opening),
            "schedule": `${data?.WorkingDays}x8 Hour ${data?.ShiftPrefernce} shift`,
            // "schedule": `${data?.WorkingDays}x${Number(data?.EndTime?.split(":")?.[0]) - Number(data?.StartTime?.split(":")?.[0])} Hour ${data?.StartTime} ${data?.EndTime}`,
            "typeId": data?.jobType,
            "professionId": Number(data?.profession),
            "licenseId": Number(data?.license),
            "specialtyId": Number(data?.specialty),
            "startDate": data?.startDate,
            "experience": Number(data?.experience),
            "rateId": Number(data?.rateType),
            "rateValue": Number(data?.Amount)?.toFixed(2),
            "assignmentDuration": data?.AssignmentDuration,
            "assignmentWorkScheduleId": data?.assignmentLength ? Number(data?.assignmentLength) : null,
            "immediateJoining": data?.immediateJoining === 'true' ? true : false,
            "responsibilities": data?.jobresponsibilities,
            "qualifications": data?.qualifications,
            "skills": data?.skills,
            "educationRequirements": data?.educationrequirements,
            "experienceRequirements": data?.expirencerequirement,
            "isActive": true,
        }
        setLoading(true)

        if (jobId > 0) {
            updateJobDetail(jobCreatePayload).then((result) => {
                if (result?.statusCode != 200) {
                    handleToastDetail({ show: true, msg: `Error in ${jobId ? 'updating' : 'creating'} job`, type: 'error' });
                    return;
                }
                handleToastDetail({ show: true, msg: `Successfully ${jobId ? 'updated' : 'created'} job`, type: 'success' });
                navigate('/admin/listings')
            }).catch(e => {
                handleToastDetail({ show: true, msg: `Error in ${jobId ? 'updating' : 'creating'} job`, type: 'error' })
            }).finally(() => {
                setLoading(false)
            })
            return;
        }

        createNewJob(jobCreatePayload).then((result) => {
            if (result?.statusCode != 200) {
                handleToastDetail({ show: true, msg: `Error in ${jobId ? 'updating' : 'creating'} job`, type: 'error' });
                return;
            }
            handleToastDetail({ show: true, msg: `Successfully ${jobId ? 'updated' : 'created'} job`, type: 'success' });
            navigate('/admin/listings');
            reset();
        }).catch(e => {
            handleToastDetail({ show: true, msg: `Error in ${jobId ? 'updating' : 'creating'} job`, type: 'error' })
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Loader loading={isLoading} />
            {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
            <div className="create-job-section">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <div className="col-lg-3 col-xxl-2 create-job-leftnav-container position-relative">
                            {/* <!--  sidebar--> */}
                            <div className="left-nav">
                                <ul>
                                    <li><a onClick={() => scrollToPosition("sectionJobDescription", ActiveSection.sectionJobDescription)} className={`pointer-cursor  ${activeSection == ActiveSection.sectionJobDescription ? "left-nav-active" : ""}`} >Job Description</a></li>
                                    <li><a onClick={() => scrollToPosition("sectionJobResponsibilities", ActiveSection.sectionJobResponsibilities)} className={`pointer-cursor  ${activeSection == ActiveSection.sectionJobResponsibilities ? "left-nav-active" : ""}`}  >Job Responsibilities</a></li>
                                    <li><a onClick={() => scrollToPosition("sectionQualifications", ActiveSection.sectionQualifications)} className={`pointer-cursor  ${activeSection == ActiveSection.sectionQualifications ? "left-nav-active" : ""}`}  >Qualifications</a></li>
                                    <li><a onClick={() => scrollToPosition("sectionSkills", ActiveSection.sectionSkills)} className={`pointer-cursor  ${activeSection == ActiveSection.sectionSkills ? "left-nav-active" : ""}`}  >Skills</a></li>
                                    <li><a onClick={() => scrollToPosition("sectionEducationRequirements", ActiveSection.sectionEducationRequirements)} className={`pointer-cursor  ${activeSection == ActiveSection.sectionEducationRequirements ? "left-nav-active" : ""}`}  >Education Requirements</a></li>
                                    <li><a onClick={() => scrollToPosition("sectionExperienceRequirements", ActiveSection.sectionExperienceRequirements)} className={`pointer-cursor  ${activeSection == ActiveSection.sectionExperienceRequirements ? "left-nav-active" : ""}`}  >Experience Requirements</a></li>
                                </ul>
                            </div>
                            {/* <!--  sidebar ends--> */}
                        </div>
                        <div className="col-lg-9 col-xxl-10 ">
                            {/* <!--Search Results start--> */}
                            {/* <!--Search Job DDL--> */}
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4>{Number(jobId) > 0 ? 'Edit existing' : 'Create New'} Job </h4>
                                    </div>
                                    <div className="col-lg-6 text-lg-end">
                                        {
                                            Number(jobId) > 0 ? (
                                                <BreadCrumbContent SectionName={endPoints.EDITJOBPOSTING} />
                                            ) : (
                                                <BreadCrumbContent SectionName={endPoints.CREATEJOBPOSTING} />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="py-2">

                                {/* <!--Results starts--> */}
                                <div className="container-fluid">
                                    <div className="row">
                                        {/* <!--Single result - Repeat this--> */}
                                        <div className="col-lg-12">
                                            <div className="page-title"><i className="fa fa-briefcase" aria-hidden="true"></i> {Number(jobId) > 0 ? 'Edit existing' : 'Create New'} Job Post</div>
                                        </div>
                                        <div className="col-lg-12 mb-4">
                                            <div className="create-job-wrapper">
                                                <form onSubmit={handleSubmit(onSubmit)}>
                                                    <section id="sectionJobDescription" data-anchor="sectionJobDescription" data-scroll="sectionJobDescription">
                                                        <div className="container-fluid">
                                                            <h4 className="section-title">Job Description</h4>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-md-12 mb-3">
                                                                    <label className="form-label" htmlFor="txtJobtitle">Job title *:</label>
                                                                    <input type="text" className="form-control" id="txtJobtitle" {...register("jobTitle", { required: true })} />
                                                                    {errors.jobTitle && <span className="text-danger">Job title is required</span>}
                                                                </div>
                                                                <div className="col-md-2 mb-3">
                                                                    <label className="form-label" htmlFor="ddlState">State *:</label>
                                                                    <select className="form-select" id="ddlState" {...register("State", { required: true })} onChange={(e) => stateUpdated(e)} >
                                                                        <option value="" selected disabled>Select</option>
                                                                        {stateData?.map((data: any) => (
                                                                            <option key={'state' + data.name} value={data.name}>{data.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.State && <span className="text-danger">State is required</span>}
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <label className="form-label" htmlFor="ddlCity">City *:</label>
                                                                    <select className="form-select" id="ddlCity" {...register("City", { required: true })}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {cityData?.map((data: any, index: number) => (
                                                                            <option key={`city${data}${index}`} value={data}>{data}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.City && <span className="text-danger">City is required</span>}
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <label className="form-label" htmlFor="txtWorkingDays">Working day(s) *:</label>
                                                                    <select className="form-select" id="txtWorkingDays" {...register("WorkingDays", { required: true })}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {MaxWorkingDaysInWeek?.map((data: any, index: number) => (
                                                                            <option key={'wkd' + index} value={data}>{data}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.WorkingDays && <span className="text-danger">Working days count is required</span>}
                                                                </div>
                                                                {/* <div className="col-md-2 mb-3">
                                                                    <label className="form-label" htmlFor="txtStartTime">Start Time:</label>
                                                                    <select className="form-select" id="txtStartTime" {...register("StartTime", { required: false })}>
                                                                        {ScheduleAvailableTimings.map((data: any, index: number) => (
                                                                            <option key={'start' + index} value={data}>{data}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.StartTime && <span className="text-danger">StartTime is required</span>}
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <label className="form-label" htmlFor="txtEndTime">End Time:</label>
                                                                    <select className="form-select" id="txtEndTime" {...register("EndTime", { required: false, validate: validateEndDate })}>
                                                                        {ScheduleAvailableTimings.map((data: any, index: number) => (
                                                                            <option key={'end' + index} value={data}>{data}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.EndTime && (<span className="text-danger">{errors.EndTime.message as any}</span>)}
                                                                </div> */}
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="shittPrefer">Shift Preference *:</label>
                                                                    <select className="form-select" id="shittPrefer" {...register("ShiftPrefernce", { required: true })}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {ShiftPreferenceTypes?.map((data: any, index: number) => (
                                                                            <option key={'sft' + index} value={data}>{data}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.ShiftPrefernce && <span className="text-danger">ShiftPrefernce is required</span>}
                                                                </div>
                                                                <div className="col-md-12 mb-3">
                                                                    <label className="form-label d-block">Type *:</label>
                                                                    {jobtypesdata?.map((option: any) => (
                                                                        <label key={`option${option.typeId}`} htmlFor={`option${option.typeId}`} className="form-label form-label-custom-radio">
                                                                            <input
                                                                                type="radio"
                                                                                id={`option${option.typeId}`}
                                                                                value={option.typeId}
                                                                                {...register("jobType", { required: true })}
                                                                            />
                                                                            {option.name}
                                                                        </label>
                                                                        // </div>
                                                                    ))}
                                                                    {errors.jobType && <span className="text-danger">Job type is required</span>}
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="txtOpening">Opening *:</label>
                                                                    <input type="number" className="form-control" id="txtOpening" {...register("opening", { required: true })} min="1" />
                                                                    {errors.opening && <span className="text-danger">No. of opening is required</span>}
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="ddlProfession">Profession *:</label>
                                                                    <select className="form-select" id="ddlProfession" {...register("profession", { required: true })} onChange={(e) => professionChange(e)} >
                                                                        <option value="" selected disabled>Select</option>
                                                                        {AllProfessions?.map((data: any) => (
                                                                            <option key={'profession' + data.professionId} value={data.professionId}>{data.professionName}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.profession && <span className="text-danger">Profession is required</span>}
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="ddlLicense">License *:</label>
                                                                    <select className="form-select" id="ddlLicense" {...register("license", { required: true })} onChange={(e) => licenseChange(e)}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {joblicensesdata?.map((data: any) => (
                                                                            <option key={'licesnse' + data.licenseId} value={data.licenseId}>{data.licenseName}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.license && <span className="text-danger">License is required</span>}
                                                                </div>
                                                                <div className="col-md-8 mb-3">
                                                                    <label className="form-label" htmlFor="ddlSpecialty">Specialty *:</label>
                                                                    <select className="form-select" id="ddlSpecialty" {...register("specialty", { required: true })} >
                                                                        <option value="" selected disabled>Select</option>
                                                                        {specialtiesdata?.map((data: any) => (
                                                                            <option key={'speciality' + data.specialtyId} value={data.specialtyId}>{data.specialtyName}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.specialty && <span className="text-danger">Specialty is required</span>}
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="txtStartDate">Start Date *:</label>
                                                                    <input type="date" className="form-control" min={new Date().toJSON().slice(0, 10)} id="txtStartDate" {...register("startDate", { required: true })} />
                                                                    {errors.startDate && <span className="text-danger">Start date is required</span>}
                                                                </div>

                                                                <div className="col-md-2 mb-3">
                                                                    <label className="form-label" htmlFor="txtAmount">Amount *:</label>
                                                                    <input type="number" className="form-control" id="txtAmount" {...register("Amount", { required: true })} min="1" />
                                                                    {errors.Amount && <span className="text-danger">Amount is required</span>}
                                                                </div>
                                                                <div className="col-md-3 mb-3">
                                                                    <label className="form-label" htmlFor="ddlRateType">Rate Type*:</label>
                                                                    <select className="form-select" id="ddlRateType" {...register("rateType", { required: true })}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {rateType?.map((data: any) => (
                                                                            <option key={'rate' + data?.rateId} value={data?.rateId}>{data?.description}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.rateType && <span className="text-danger">Rate Type is required</span>}
                                                                </div>

                                                                <div className="col-md-3 mb-3">
                                                                    <label className="form-label" htmlFor="AssignmentDuration">Minimum Duration *:</label>
                                                                    <input type="number" className="form-control" id="AssignmentDuration" {...register("AssignmentDuration", { required: true })} min="1" />
                                                                    {errors.AssignmentDuration && <span className="text-danger"> Minimum Duration is required</span>}
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="ddAssignmentLength"> Schedule *:</label>
                                                                    <select className="form-select" id="ddAssignmentLength" {...register("assignmentLength", { required: true })}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {AssignmentWorkSchedule?.map((data: any) => (
                                                                            <option key={'assignmentlength' + data?.assignmentWorkScheduleId} value={data?.assignmentWorkScheduleId}>{data?.name}</option>
                                                                        ))}
                                                                        {/* Add options for assignment length */}
                                                                    </select>
                                                                    {errors.assignmentLength && <span className="text-danger">Schedule type is required</span>}
                                                                </div>
                                                                <div className="col-md-4 mb-3">
                                                                    <label className="form-label" htmlFor="ddlExperience">Experience (in years) *:</label>
                                                                    <select className="form-select" id="ddlExperience" {...register("experience", { required: true })}>
                                                                        <option value="" selected disabled>Select</option>
                                                                        {expirenceInYears?.map(data => (
                                                                            <option key={'expirence' + data} value={data}>{data}</option>
                                                                        ))}
                                                                    </select>
                                                                    {errors.experience && <span className="text-danger">Experience is required</span>}
                                                                </div>
                                                                <div className="col-md-8 mb-3">
                                                                    <label className="form-label d-block">Immediate Joining:</label>
                                                                    <label className="form-label form-label-custom-radio" htmlFor="radioImmediateJoiningYes">
                                                                        <input type="radio" value="true" className="form-check-input" id="radioImmediateJoiningYes" {...register("immediateJoining", { required: false })} />
                                                                        Yes
                                                                    </label>
                                                                    <label className="form-label form-label-custom-radio" htmlFor="radioImmediateJoiningNo">
                                                                        <input type="radio" value="false" className="form-check-input" id="radioImmediateJoiningNo" {...register("immediateJoining", { required: false })} />
                                                                        No
                                                                    </label>
                                                                    {errors.immediateJoining && <span className="text-danger">Immediate joining is required</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <!--Job Description Ends--> */}
                                                    <hr className="mt-0 mb-3" />
                                                    {/* <!--Job Responsibilities starts--> */}
                                                    <section id="sectionJobResponsibilities" data-anchor="sectionJobResponsibilities" data-scroll="sectionJobResponsibilities">
                                                        <div className="container-fluid">
                                                            <h4 className="section-title mb-0">Job Responsibilities</h4>
                                                            <p className="text-muted">Describe the responsibilities for this job</p>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">

                                                                    <HtmlEditor contentUpdate={contentUpdate} value={jbresp} keyName={'jobresponsibilities'} />
                                                                    {/* <textarea className="form-control" aria-label="Job Responsibility" rows={6}  {...register("jobresponsibilities", { required: false })}></textarea>
                                                                    {errors.jobresponsibilities && <span className="text-danger">Job responsibilities are required</span>} */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <!--Job Responsibilities ends--> */}
                                                    <hr className="mt-0 mb-3" />
                                                    {/* <!--Qualifications Starts--> */}
                                                    <section id="sectionQualifications" data-anchor="sectionQualifications" data-scroll="sectionQualifications">
                                                        <div className="container-fluid">
                                                            <h4 className="section-title mb-0">Qualifications</h4>
                                                            <p className="text-muted">Mention the qualifications required for this job </p>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    {/* <textarea className="form-control" aria-label="Qualification" rows={6}  {...register("qualifications", { required: false })}></textarea>
                                                                    {errors.qualifications && <span className="text-danger">Qualification is required</span>} */}
                                                                    <HtmlEditor contentUpdate={contentUpdate} value={jbquality} keyName={'qualifications'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <!--Qualifications Ends--> */}
                                                    <hr className="mt-0 mb-3" />
                                                    {/* <!--Skills Starts--> */}
                                                    <section id="sectionSkills" data-anchor="sectionSkills" data-scroll="sectionSkills">
                                                        <div className="container-fluid">
                                                            <h4 className="section-title mb-0">Skills</h4>
                                                            <p className="text-muted">Mention the skills required for this job </p>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    {/* <textarea className="form-control" aria-label="Skills" rows={6}  {...register("skills", { required: false })}></textarea>
                                                                    {errors.skills && <span className="text-danger">Skill(s) are required</span>} */}
                                                                    <HtmlEditor contentUpdate={contentUpdate} value={skill} keyName={'skills'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <!--Skills Starts--> */}
                                                    <hr className="mt-0 mb-3" />
                                                    {/* <!--Education Requirements Starts--> */}
                                                    <section id="sectionEducationRequirements" data-anchor="sectionEducationRequirements" data-scroll="sectionEducationRequirements">
                                                        <div className="container-fluid">
                                                            <h4 className="section-title mb-0">Education Requirements</h4>
                                                            <p className="text-muted">What are the education required for this job </p>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    {/* <textarea className="form-control" aria-label="Education Requirements" rows={6}  {...register("educationrequirements", { required: false })}></textarea>
                                                                    {errors.educationrequirements && <span className="text-danger">Education Detail is required</span>} */}
                                                                    <HtmlEditor contentUpdate={contentUpdate} value={education} keyName={'educationrequirements'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <!--Education Requirements Starts--> */}
                                                    <hr className="mt-0 mb-3" />
                                                    {/* <!--Experience Requirements Starts--> */}
                                                    <section id="sectionExperienceRequirements" data-anchor="sectionExperienceRequirements" data-scroll="sectionExperienceRequirements">
                                                        <div className="container-fluid">
                                                            <h4 className="section-title mb-0">Experience Requirements</h4>
                                                            <p className="text-muted">Which experience required for this job </p>
                                                        </div>
                                                        <div className="container-fluid">
                                                            <div className="row">
                                                                <div className="col-lg-12 mb-3">
                                                                    {/* <textarea className="form-control" aria-label="Experience Requirements" rows={6}  {...register("expirencerequirement", { required: false })}></textarea>
                                                                    {errors.expirencerequirement && <span className="text-danger">Experience is required</span>} */}
                                                                    <HtmlEditor contentUpdate={contentUpdate} value={expirence} keyName={'expirencerequirement'} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </section>
                                                    {/* <!--Education Requirements Starts--> */}

                                                    <div className="container-fluid text-end">
                                                        <button className="btn btn-secondary py-2 px-5 mb-2 mb-lg-0" onClick={e => navigate('/admin/listings')} type="button">Cancel</button>
                                                        <button className="btn btn-dark py-2 px-5 ms-2 mb-2 mb-lg-0" type="submit" >{jobId > 0 ? 'Update' : 'Post this'} job</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--Results ends--> */}
                            </div>
                            {/* <!--Search Results ends--> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CreateJob;