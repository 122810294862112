import { useEffect, useState } from "react";
import BreadCrumbContent from "../../components/common-views/breadcrumb";
import { endPoints } from "../../utils/expoints.utils";
import { getAuthToken } from "../../utils/services/localstorage.service";
import { useNavigate } from "react-router-dom";
import { getAllJobs, getAppliedUsersList, getReferredUserList, removeJob } from "../../utils/services/jobs.service";
import { StartOfDay } from "../../utils/date.util";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../components/loader/loader";
import Toast from "../../components/toaster/toaster";
import { getApplyCandidateLists, getReferredCandidateLists } from "../../utils/services/lookup.service";

export enum TableFilter {
    All,
    Active,
    Expired
}

const AdminJobListing = () => {
    const [userLogged] = useState(getAuthToken());
    const [currentActiveSection, setCurrentActiveSection] = useState(TableFilter.All);
    const [jobListingItems, setjobListingItems] = useState([]);
    const [modalShow, setmodalShow] = useState(false)
    const [modalType, setmodalType] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [showToast, setshowToast] = useState(false);
    const [toasterMsg, setToastMsg] = useState('');
    const [toasterType, setToasterType] = useState('');
    const [selectedJob, setSelectedJob] = useState(0)
    const [appliedProfiles, setAppliedProfiles] = useState<any[]>([]);
    const [candidateList, setcandidateList] = useState<any[]>([]);
    const [referedCandidateList, setreferedCandidateList] = useState<any[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (!userLogged) {
            navigate('/admin/login')
        }
    }, [userLogged]);

    useEffect(() => {
        if (userLogged) {
            getJobList();
            getCandidateLists();
            getReferredUsers();
        }
    }, [userLogged])

    const getJobList = () => {
        getAllJobs().then((response: any) => {
            response = {
                ...response,
                result: response?.result?.map((val: any) => {
                    return {
                        ...val,
                        isExpired: new Date(val.startDate).getTime() < new Date(StartOfDay()).getTime()
                    }
                })
            }
            setjobListingItems(response?.result)
        }).catch(e => {
            console.error(e)
        })
    }

    const getCandidateLists = () => {
        getApplyCandidateLists().then((resp: any) => {
            setcandidateList(resp?.result);
        }).catch((e: any) => console.log(e))
    }

    const getReferredUsers = () => {
        getReferredCandidateLists().then((resp: any) => {
            setreferedCandidateList(resp?.result);
        }).catch((e: any) => console.log(e))
    }

    const UpdateTableActiveSection = (NewSection: any) => {
        if (currentActiveSection == NewSection) {
            return;
        }
        setCurrentActiveSection(NewSection)
    }

    const createNewJob = () => {
        navigate('/admin/create-job')
    };

    const handleNavigation = (jobId: any) => {
        navigate(`/admin/edit-job/${jobId}`)
    }

    const handleToastDetail = (toastContent: any) => {
        setshowToast(toastContent?.show)
        setToastMsg(toastContent?.msg)
        setToasterType(toastContent?.type)
    }

    useEffect(() => {
        if (['applied', 'referred'].includes(modalType)) {
            setLoading(true);
            console.log(modalType)
            if (modalType == 'applied') {
                getAppliedUsersList(selectedJob).then((result: any) => {
                    if (result?.statusCode != 200) {
                        handleToastDetail({ show: true, msg: result?.message, type: 'error' })
                        return;
                    }
                    setAppliedProfiles(result?.result);
                }).catch(err => {
                    handleToastDetail({ show: true, msg: err?.response?.data?.message, type: 'error' })
                }).finally(() => {
                    setLoading(false);
                })
                return;
            }
            getReferredUserList(selectedJob).then((result: any) => {
                if (result?.statusCode != 200) {
                    handleToastDetail({ show: true, msg: result?.message, type: 'error' })
                    return;
                }
                setAppliedProfiles(result?.result);
            }).catch(err => {
                handleToastDetail({ show: true, msg: err?.response?.data?.message, type: 'error' })
            }).finally(() => {
                setLoading(false);
            })
        }
    }, [modalType])

    const confirmationCloseHandle = (btnClick: boolean) => {
        if (btnClick) {
            setLoading(true)
            removeJob(selectedJob).then((response: any) => {
                if (response?.statusCode != 200) {
                    handleToastDetail({ show: true, msg: response?.message, type: 'error' })
                    return;
                }
                handleToastDetail({ show: true, msg: response?.message, type: 'success' });
                getJobList();
            }).catch((err: any) => {
                handleToastDetail({ show: true, msg: err?.response?.data?.message, type: 'error' })

            }).finally(() => {
                setLoading(false)
            })
            handleClose();
            return;
        }
        handleClose();
    }

    const handleClose = () => {
        setmodalShow(false)
        setAppliedProfiles([])
        setmodalType('')
    }

    const handleOpen = (modalType: string, jobId: any = 0) => {
        setSelectedJob(jobId);
        setmodalType(modalType);
        setmodalShow(true)
    }

    const openFile = (fileName: any) => {
        if (!fileName) {
            return;
        }
        window.open(fileName, '_blank')
    }

    return (
        <>
            <Loader loading={isLoading} />
            {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
            <div className="container-fluid mt-3">
                <div className="row">
                    <div className="col-lg-6"><h4>Admin Home </h4></div>
                    <div className="col-lg-6 text-lg-end">
                        <BreadCrumbContent SectionName={endPoints.ADMINJOBLISITNG} />
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 mb-4">
                        <nav>
                            <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <button className="nav-link active" id="nav-tab1-tab" data-bs-toggle="tab" data-bs-target="#nav-tab1" type="button" role="tab" aria-controls="nav-tab1" aria-selected="true">All Jobs</button>
                                <button className="nav-link" id="nav-tab2-tab" data-bs-toggle="tab" data-bs-target="#nav-tab2" type="button" role="tab" aria-controls="nav-tab2" aria-selected="false">Applied Candidates</button>
                                <button className="nav-link" id="nav-tab3-tab" data-bs-toggle="tab" data-bs-target="#nav-tab3" type="button" role="tab" aria-controls="nav-tab3" aria-selected="false">Referrals Candidates</button>
                            </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-tab1" role="tabpanel" aria-labelledby="nav-tab1-tab">
                                {/* <!--Jobs Tabs--> */}
                                <div className="create-job-wrapper border-top-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-6"><i className="fa fa-briefcase" aria-hidden="true"></i> <strong>Jobs</strong>  <button className="btn btn-dark ms-2 py-1" onClick={() => createNewJob()}>Add New job</button></div>
                                        <div className="col-lg-6 text-end">
                                            <input type="text" className="form-control d-inline-block w-50 form-control-sm" aria-label="Search Jobs" />
                                            <button className="btn btn-secondary ms-1 py-1 d-inline-block">Search Jobs</button>
                                        </div>
                                    </div>

                                    <div className="grid-filter-wrapper my-3">
                                        <a onClick={() => UpdateTableActiveSection(TableFilter.All)} className={`pointer-cursor  ${currentActiveSection == TableFilter.All ? "grid-active-filter" : ""}`}>All</a> |
                                        <a onClick={() => UpdateTableActiveSection(TableFilter.Active)} className={`pointer-cursor ${currentActiveSection == TableFilter.Active ? "grid-active-filter" : ""}`}>Active </a> |
                                        <a onClick={() => UpdateTableActiveSection(TableFilter.Expired)} className={`pointer-cursor  ${currentActiveSection == TableFilter.Expired ? "grid-active-filter" : ""}`}>Expired</a>
                                    </div>
                                    <div className="table-responsive custom-grid-container">
                                        <table className="table table-bordered table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><input type="checkbox" /></th>
                                                    <th scope="col">Job Title</th>
                                                    <th scope="col">Job Type</th>
                                                    <th scope="col">Location</th>
                                                    <th scope="col">Profession</th>
                                                    <th scope="col">Specialty</th>
                                                    <th scope="col">Rate</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    jobListingItems.filter((value: any) => currentActiveSection == TableFilter.All ? true : (
                                                        currentActiveSection == TableFilter.Active ? !value?.isExpired : value.isExpired
                                                    )).map((data: any) => (
                                                        <tr key={'row' + data?.jobId} >
                                                            <td><input type="checkbox" /></td>
                                                            <td>{data?.jobTitle}</td>
                                                            <td>{data?.jobType?.name}</td>
                                                            <td>{data?.cityName}, {data?.stateName}</td>
                                                            <td>{data?.profession?.professionName}</td>
                                                            <td>{data?.specialty?.specialtyName}</td>
                                                            <td>${data?.rateValue?.toFixed(2)} {data?.rate?.description}</td>
                                                            <td>
                                                                {
                                                                    data?.isExpired ? (
                                                                        <span className="text-danger">Expired</span>
                                                                    ) : (
                                                                        <span className="text-success">Active</span>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                <i title="Edit" className="fa fa-pencil-square-o mx-1" onClick={e => handleNavigation(data?.jobId)} aria-hidden="true"></i>
                                                                <i title="Remove" className="fa fa-trash mx-1" onClick={e => handleOpen('delete', data?.jobId)} aria-hidden="true"></i>
                                                                <i title="See Applied Profile" className="fa fa-briefcase mx-1" onClick={e => handleOpen('applied', data?.jobId)} aria-hidden="true"></i>
                                                                <i title="See Referred Profile" className="fa fa-share-alt mx-1" onClick={e => handleOpen('referred', data?.jobId)} aria-hidden="true"></i>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="row align-items-center">
                                        <div className="col-lg-12 text-end"><button className="btn btn-dark ms-2 py-1" onClick={() => createNewJob()}>Add New job</button></div>
                                    </div>
                                </div>
                                {/* <!--Jobs Tabs Ends--> */}
                            </div>
                            <div className="tab-pane fade" id="nav-tab2" role="tabpanel" aria-labelledby="nav-tab2-tab">
                                {/* <!-- Tabs 2 starts--> */}
                                <div className="create-job-wrapper border-top-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="table-responsive custom-grid-container">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">First name</th>
                                                            <th scope="col">Last name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Phone no.</th>
                                                            <th scope="col">Resume</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            candidateList?.length ?
                                                                (candidateList?.map((data: any, index: number) => (
                                                                    <tr key={'rowapply' + index} >
                                                                        <td>{data?.firstName}</td>
                                                                        <td>{data?.lastName}</td>
                                                                        <td>{data?.emailAddress}</td>
                                                                        <td>{data?.phoneNumber}</td>
                                                                        <td>
                                                                            {
                                                                                data?.resumeFilePath && (
                                                                                    <i className="fa fa-file-zip-o" onClick={e => openFile(data?.resumeFilePath)} ></i>
                                                                                )
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))) : (
                                                                    <tr>
                                                                        <td colSpan={5} className="text-center">
                                                                            <span >No record found</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!-- Tabs 2 ends--> */}
                            </div>
                            <div className="tab-pane fade" id="nav-tab3" role="tabpanel" aria-labelledby="nav-tab3-tab">
                                <div className="create-job-wrapper border-top-0">
                                    <div className="row align-items-center">
                                        <div className="col-lg-12">
                                            <div className="table-responsive custom-grid-container">
                                                <table className="table table-bordered table-striped table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">First name</th>
                                                            <th scope="col">Last name</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Referral First name</th>
                                                            <th scope="col">Referral Last name</th>
                                                            <th scope="col">Referral Email</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            referedCandidateList?.length ?
                                                                (referedCandidateList?.map((data: any) => (
                                                                    <tr key={'rowreferal' + data?.jobId} >
                                                                        <td>{data?.firstName}</td>
                                                                        <td>{data?.lastName}</td>
                                                                        <td>{data?.emailAddress}</td>
                                                                        <td>{data?.referralFirstName}</td>
                                                                        <td>{data?.referralLastName}</td>
                                                                        <td>{data?.referralEmailAddress}</td>
                                                                    </tr>
                                                                ))) : (
                                                                    <tr>
                                                                        <td colSpan={6} className="text-center">
                                                                            <span >No record found</span>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

            {/* Modal Section for Apply and Refer and Delete Confirmation */}

            {
                modalType == 'delete' && (
                    <Modal show={modalShow} onHide={handleClose} >
                        <Modal.Header closeButton>
                            <Modal.Title>Confirmation</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to remove this job ?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={e => confirmationCloseHandle(false)}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={e => confirmationCloseHandle(true)}>
                                Yes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

            {
                modalType == 'applied' && (
                    <Modal show={modalShow} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>Applied Candidates List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="table-responsive custom-grid-container">
                                <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">E-Mail</th>
                                            <th scope="col">Phone No.</th>
                                            <th scope="col">Job Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appliedProfiles?.length === 0 && (
                                            <tr>
                                                <td colSpan={5} className="text-center">No record found</td>
                                            </tr>
                                        )}
                                        {
                                            appliedProfiles.map((data: any) => (
                                                <tr>
                                                    <td>{data?.firstName}</td>
                                                    <td>{data?.lastName}</td>
                                                    <td>{data?.emailAddress}</td>
                                                    <td>{data?.phoneNumber}</td>
                                                    <td>{data?.job?.jobTitle}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }

            {
                modalType == 'referred' && (
                    <Modal show={modalShow} onHide={handleClose} size="xl">
                        <Modal.Header closeButton>
                            <Modal.Title>Referred Candidates List</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="table-responsive custom-grid-container">
                                <table className="table table-bordered table-striped table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">E-Mail</th>
                                            <th scope="col">Phone No.</th>
                                            <th scope="col">Job Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {appliedProfiles?.length === 0 && (
                                            <tr>
                                                <td colSpan={5} className="text-center">No record found</td>
                                            </tr>
                                        )}
                                        {
                                            appliedProfiles.map((data: any) => (
                                                <tr>
                                                    <td>{data?.referralFirstName}</td>
                                                    <td>{data?.referralLastName}</td>
                                                    <td>{data?.referralEmailAddress}</td>
                                                    <td>{data?.referralPhoneNumber}</td>
                                                    <td>{data?.job?.jobTitle}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )
            }


            {/* Modal section for  */}
        </>
    );
}

export default AdminJobListing;