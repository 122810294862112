import { API_END_POINTS } from "../api-endpoint.util";
import { axiosGet } from "./axios.instance"

export const LookpData = {
    schedules: null,
    jobtypes: null,
    professions: null,
    joblicenses: null,
    specialties: null,
    ratetype: null,
    shifttype: null,
    WorkSchedules: null
}
export const gettates = () => {
    return axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.States}`)
}

export const citiesOfState = (stateId: number) => {
    return axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.City}${stateId}`)
}
export const schedules = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.schedules !== null) {
            resolve(LookpData.schedules)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.Schedules}`).then(res => {
            LookpData.schedules = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });

    })
}
export const jobtypes = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.jobtypes !== null) {
            resolve(LookpData.jobtypes)
            return;
        }

        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.Jobtypes}`).then(res => {
            LookpData.jobtypes = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });
    })
}
export const professions = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.professions !== null) {
            resolve(LookpData.professions)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.Professions}`).then(res => {
            LookpData.professions = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });
    })
}
export const joblicenses = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.joblicenses !== null) {
            resolve(LookpData.joblicenses)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.Joblicense}`).then(res => {
            LookpData.joblicenses = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });

    })
}
export const specialties = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.specialties !== null) {
            resolve(LookpData.specialties)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.Specialties}`).then(res => {
            LookpData.specialties = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });

    })
}

export const ratePreference = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.ratetype !== null) {
            resolve(LookpData.ratetype)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.RateType}`).then(res => {
            LookpData.ratetype = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });

    })
}

export const shiftPreference = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.shifttype !== null) {
            resolve(LookpData.shifttype)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.ShiftPrefernce}`).then(res => {
            LookpData.shifttype = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });

    })
}

export const workSchedules = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.WorkSchedules !== null) {
            resolve(LookpData.WorkSchedules)
            return;
        }
        axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.WorkSchedules}`).then(res => {
            LookpData.WorkSchedules = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });

    })
}


export const getReferredCandidateLists = () => {
    return axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.ReferralCandidate}`);
}

export const getApplyCandidateLists = () => {
    return axiosGet(`${API_END_POINTS.BasePath.Lookup}${API_END_POINTS.EndPoints.Lookup.QuickApply}`);
}