import icon_briefcasePlus from '../../assets/images/icon_briefcasePlus.svg'
import icon_badge from '../../assets/images/icon_badge.svg'
import icon_clock from '../../assets/images/icon_clock.svg'
import icon_money from '../../assets/images/icon_money.svg'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import ReferralSubmitSection from '../common-views/referral-submit'

const JobDetailCard = ({ JobDetails }: any) => {
    const navigate = useNavigate();
    const params = useParams()

    const [modalOpen, setOpneModal] = useState(false);

    const handleRefer = () => {
        setOpneModal(true);
    }

    const handleClose = () => {
        setOpneModal(false)
    }

    const confirmationCloseHandle = () => {
        handleClose()
    }

    return (
        <>
            <div className="result-item-container">
                <div className="d-flex justify-content-between flex-wrap align-items-center">
                    {/* <div className="col-6"><span
                        className="badge bg-secondary bg-opacity-25 text-uppercase rounded-4 text-black px-3">Travel</span>
                    </div> */}
                    {JobDetails?.immediateJoining && (
                        <div className="col-12 text-end">
                            <span className="text-ornage">
                                <i className="fa fa-hourglass-half"></i> Immediate Offer
                            </span>
                        </div>
                    )}

                    <div className="col-12 mt-1">
                        <div><strong>{JobDetails?.jobTitle}</strong></div>
                    </div>
                    <div className="col-12 mt-2">
                        <div><strong>{JobDetails?.cityName}, {JobDetails?.stateName}</strong></div>
                        <div className="text-muted small">{JobDetails?.profession?.professionName}</div>
                    </div>
                    <div className="col-6 mt-2">
                        <img src={icon_briefcasePlus} className="result-item-icon" alt="icon1" />
                        <span className="text-size-0dot85 lh-1">{JobDetails?.license?.licenseName || 'N/a'}</span> </div>
                    <div className="col-6 mt-2">
                        <img src={icon_badge} className="result-item-icon" alt="icon2" />
                        <span className="text-size-0dot85">{JobDetails?.specialty?.specialtyName}</span> </div>
                    <div className="col-6 mt-2">
                        <img src={icon_clock} className="result-item-icon" alt="icon3" />
                        <span className="text-size-0dot85">{JobDetails?.assignmentDuration} {JobDetails?.assignmentWorkSchedule?.name}</span> </div>
                    <div className="col-6 mt-2">
                        <img src={icon_money} className="result-item-icon" alt="icon4" />
                        <span className="text-size-0dot85">${JobDetails?.rateValue}/{JobDetails?.rate?.rateType}</span> </div>
                </div>
                <div className="d-flex flex-wrap justify-content-between mt-3 align-items-center">
                    <div className='mb-2'>
                        <NavLink to={'/jobs/' + JobDetails?.jobId} >
                            View Details
                        </NavLink>
                    </div>
                    <div className='mb-2'>
                        <button className="btn btn-sm btn-outline-primary" onClick={() => handleRefer()}>REFER</button>
                        <button className="btn btn-sm btn-primary ms-2" onClick={() => navigate(`/apply/job/${JobDetails?.jobId}`)} >I'M INTERESTED</button>
                    </div>
                </div>
            </div>

            <ReferralSubmitSection modalOpen={modalOpen} handleClose={handleClose} JobId={JobDetails?.jobId} confirmationCloseHandle={confirmationCloseHandle} />

        </>
    )
}

export default JobDetailCard;