import { API_END_POINTS } from "../api-endpoint.util";
import { axiosDelete, axiosGet, axiosPost, axiosPut } from "./axios.instance"

export const LookpData = {
    ProfessionsList: null,
}

export const createNewJob = (payload: any) => {
    return axiosPost(API_END_POINTS.BasePath.Jobs, payload);
}

export const JobProfessionLists = () => {
    return new Promise((resolve, reject) => {
        if (LookpData.ProfessionsList !== null) {
            resolve(LookpData.ProfessionsList)
            return;
        }
        return axiosGet(`${API_END_POINTS.BasePath.Jobs}${API_END_POINTS.EndPoints.Jobs.Profession}`).then(res => {
            LookpData.ProfessionsList = res?.result;
            resolve(res?.result);
        }).catch(e => {
            reject([]);
        });
    })
}

export const SearchJobsWithFilter = (payload: any) => {
    return axiosPost(`${API_END_POINTS.BasePath.Jobs}${API_END_POINTS.EndPoints.Jobs.Search}`, payload);
}

export const getAllJobs = () => {
    return axiosGet(API_END_POINTS.BasePath.Jobs);
}

export const getJobDetail = (JobId: any) => {
    return axiosGet(`${API_END_POINTS.BasePath.Jobs}/${JobId}`);
}

export const updateJobDetail = (payload: any) => {
    return axiosPut(`${API_END_POINTS.BasePath.Jobs}`, payload);
}

export const removeJob = (JobId: any) => {
    return axiosDelete(`${API_END_POINTS.BasePath.Jobs}/${JobId}`);
}

export const sendEmailData = (payload: any) => {
    return axiosPost(`${API_END_POINTS.BasePath.Email}${API_END_POINTS.EndPoints.Jobs.SendEmail}`, payload)
}

export const getAppliedUsersList = (jobId: any) => {
    return axiosGet(`${API_END_POINTS.BasePath.QuickApply}/job/${jobId}`)
}

export const applyForProfile = (payload: any) => {
    return axiosPost(`${API_END_POINTS.BasePath.QuickApply}`, payload);
}

export const getReferredUserList = (jobId: any) => {
    return axiosGet(`${API_END_POINTS.BasePath.Referral}/job/${jobId}`)
}

export const addReferralForJob = (payload: any) => {
    return axiosPost(`${API_END_POINTS.BasePath.Referral}`, payload);
}

export const addResumeWithApply = (payload: any, queryParam: any) => {
    return axiosPost(`${API_END_POINTS.BasePath.QuickApply}${API_END_POINTS.EndPoints.Jobs.SendEmailFileAttach}`, payload, queryParam)
}