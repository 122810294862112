import BreadCrumbContent from "../components/common-views/breadcrumb";
import JobDetailCard from "../components/jobviewinfo/job-card";
import { endPoints } from "../utils/expoints.utils";
import icon_briefcase from '../assets/images/icon_briefcase.svg';
import SidebarSearch from "../components/common-views/sidebar-search";
import { useEffect, useState } from "react";
import { SearchJobsWithFilter } from "../utils/services/jobs.service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../components/loader/loader";

const JobListingView = () => {

    const location = useLocation();
    const [avaiableJobs, setAvailableJobs] = useState<any>({});
    const [isLoading, setisLoading] = useState(false)
    const [searchObj, setSearchObj] = useState<any>({
        cityName: '',
        stateName: location?.state?.loc || '',
        specialtyIds: location?.state?.specIds || [],
    });

    const [sortId, updateSortId] = useState(0);


    const ChangeSortBy = (eve: any) => {
        updateSortId(Number(eve?.target?.value))
    }

    useEffect(() => {
        setisLoading(true)
        SearchJobsWithFilter(searchObj).then((response: any) => (window.history.replaceState({}, ''), setAvailableJobs(response?.result), updateSortId(1))).catch(e => console.error(e)).finally(() => setisLoading(false))
    }, [searchObj])

    useEffect(() => {
        if (sortId > 0) {
            let jobs = JSON.parse(JSON.stringify(avaiableJobs));
            jobs = {
                ...jobs,
                jobs: jobs?.jobs?.sort((a: any, b: any) => (sortId == 1 ? (
                    new Date(b?.createdAt)?.getTime() > new Date(a.createdAt).getTime() ? 1 : -1)
                    : (new Date(a?.createdAt)?.getTime() > new Date(b?.createdAt).getTime() ? 1 : -1))
                )
            }
            setAvailableJobs(jobs)
        }
    }, [sortId])


    const refreshSearch = (payload: any) => {
        setSearchObj({ ...searchObj, ...payload })
    }

    return (
        <>
            <Loader loading={isLoading} />
            {/* {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />} */}
            <section className="explore-hero-area">
                <div className="">
                    Explore nationwide career opportunities in the healthcare sector to
                    advance your professional journey.
                </div>
            </section>
            <section className="filter-result-section">
                <div className="container-fluid p-0">
                    <div className="row g-0">
                        <SidebarSearch refreshSearch={(e: any) => refreshSearch(e)} locl={searchObj?.stateName || ''} searchPayload={searchObj} />
                        <div className="col-lg-9 col-xxl-10 searchResult-container">
                            <div className="container-fluid mt-3">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h4>Job List</h4>
                                    </div>
                                    <div className="col-lg-6 text-lg-end">
                                        <BreadCrumbContent SectionName={endPoints.JOBLIST} />
                                    </div>
                                </div>
                            </div>

                            <div className="p-lg-3">

                                <div className="d-flex flex-wrap py-3 px-3">
                                    <div className="col-lg-6">
                                        <img src={icon_briefcase} className="img-fluid vertical-align-middle me-2" />
                                        <strong className="">{avaiableJobs?.totalCount}</strong> Available Jobs
                                    </div>
                                    <div className="col-lg-6 text-end">Sort By
                                        <select className="bg-transparent border-0" onChange={e => ChangeSortBy(e)}>
                                            <option value="1">Newest first</option>
                                            <option value="2">Oldest first</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="container-fluid">
                                    <div className="row">
                                        {avaiableJobs?.jobs?.map((data: any, idenx: number) => (
                                            <div className="col-lg-6 col-xxl-4 mb-4" key={idenx}  >
                                                <JobDetailCard JobDetails={data} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JobListingView;