
import Quotes from '../../assets/images/doubleQuotes.svg';
import TestimonyImage from '../../assets/images/testimonialImg1.png';
const TestimonialSection = () => {
    return (
        <section id="testimonials" className="py-5 bg-light testimonialsSection">
            <div className="container-fluid px-lg-5">
                <div className="row gx-lg-5 gy-3">
                    <div className="col-12">
                        {/*Carousel*/}
                        <div
                            id="carouselTestimonialCaptions"
                            className="carousel slide"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-inner">
                                <div className="carousel-item active">
                                    <div className="testimonial-carousel-caption d-flex flex-wrap">
                                        <div className="testimonial-carousel-content-container col-lg-8 col-md-7 order-2 order-md-1">
                                            <img
                                                src={Quotes}
                                                alt="quotes"
                                                className="quoteMark img-fluid mb-2"
                                            />
                                            <div className="testimonial-carousel-content lh-lg">
                                                At Volto Healthcare, I've found a home that values and
                                                empowers its nurses. The commitment to excellence is
                                                evident in top-notch training and cutting-edge resources.
                                                This supportive environment has not only elevated my
                                                career but also fostered a sense of belonging to a team
                                                dedicated to making a positive impact on healthcare. Proud
                                                to be a nurse at Volto Healthcare, where our collective
                                                efforts truly make a difference in the lives of those we
                                                serve.
                                            </div>
                                        </div>
                                        <div className="testimonial-carousel-img-container col-lg-4 col-md-5 order-1 order-md-2">
                                            <img
                                                src={TestimonyImage}
                                                className="img-fluid testimonial-carousel-img"
                                                alt="Img 1"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target="#carouselTestimonialCaptions"
                                data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target="#carouselTestimonialCaptions"
                                data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        {/*Carousel ends*/}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TestimonialSection;