import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const HtmlEditor = ({ contentUpdate, value, keyName }: any) => {
    const [editorHtml, setEditorHtml] = useState('');

    const handleChange = (html: any) => {
        setEditorHtml(html);
    };

    useEffect(() => {
        setEditorHtml(value)
    }, [value])


    useEffect(() => {
        if (editorHtml != value) {
            contentUpdate(editorHtml, keyName);
        }
    }, [editorHtml])

    return (
        <div>
            <ReactQuill
                theme="snow"
                value={editorHtml}
                onChange={handleChange}
            />
        </div>
    );
};

export default HtmlEditor;