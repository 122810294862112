import LinkedInImage from '../../assets/images/icon_linkedin.svg'
import FacebookImage from '../../assets/images/icon_facebook.svg'
import TwitterImage from '../../assets/images/icon_twitter.svg'
import YoutubeImage from '../../assets/images/icon_Youtube.svg'
import LogoImage from '../../assets/images/Logo.png';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 text-center footerlinks-container mb-3">
                        <NavLink to='/' className="footerlink">
                            Home
                        </NavLink>
                        |
                        <NavLink to='/about-us' className="footerlink">
                            About Us
                        </NavLink>
                        |
                        <NavLink to='/contact-us' className="footerlink">
                            Contact Us
                        </NavLink>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="footer-logo-container py-4">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6 text-center border-light border-end no-border-md">
                                        <p>
                                            <img
                                                src={LogoImage}
                                                className="footer-logo img-fluid"
                                            />
                                        </p>
                                        1750 E Golf Rd,Suite 1080, <br />
                                        Schaumburg, IL 60173.
                                    </div>
                                    <div className="col-md-6 socialmedia-icons-container text-size-1dot2 align-self-center text-center">
                                        Feel Free to Connect
                                        <br />
                                        +1 (224) 328-7751 <br />
                                        <div className="socialmedia-icons mt-2">
                                            <a title="Linked In" href='https://www.linkedin.com/company/81897394/admin/feed/posts/' target='_blank'>
                                                <img
                                                    src={LinkedInImage}
                                                    alt="Linked In"
                                                    className="socialmedia-icon-linkedIn"
                                                />
                                            </a>
                                            <a title="facebook" href='https://www.facebook.com/profile.php?id=61556540486997' target='_blank'>
                                                <img
                                                    src={FacebookImage}
                                                    alt="facebook"
                                                    className="socialmedia-icon-facebook"
                                                />
                                            </a>
                                          {/*  <a title="twitter">
                                                <img
                                                    src={TwitterImage}
                                                    alt="twitter"
                                                    className="socialmedia-icon-twitter"
                                                />
                                            </a>
                                            <a title="youtube">
                                                <img
                                                    src={YoutubeImage}
                                                    alt="youtube"
                                                    className="socialmedia-icon-youtube"
                                                />
                                            </a>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-3">
                        <div className="text-white small text-center">
                            © 2024 VOLTO HEALTHCARE. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>

    )
}

export default Footer;