import { useEffect, useRef, useState } from "react";
import { JobProfessionLists, sendEmailData } from "../../utils/services/jobs.service";
import { citiesOfState, gettates, jobtypes, ratePreference, workSchedules } from "../../utils/services/lookup.service";
import { useForm } from "react-hook-form";
import Loader from "../loader/loader";
import Toast from "../toaster/toaster";

const GuidanceSection = () => {

    const { register, handleSubmit, formState: { errors }, setValue, getValues, reset } = useForm();

    const [stateData, setstateData] = useState<any[]>([]);
    const [cityData, setcityData] = useState([]);
    const [jobtypesdata, setjobtypesdata] = useState([])
    const [rateType, setrateType] = useState([])
    const [AllProfessions, SetProfessions] = useState<any[]>([])
    const [joblicensesdata, setjoblicensesdata] = useState<any[]>([])
    const [specialtiesdata, setspecialtiesdata] = useState<any[]>([])

    const [rateV, setRate] = useState(1);
    const [file, setFile] = useState<any>(null);
    const [isLoading, setLoading] = useState(false);
    const [showToast, setshowToast] = useState(false);
    const [toasterMsg, setToastMsg] = useState('');
    const [toasterType, setToasterType] = useState('');
    const fileInputRef = useRef(null)


    useEffect(() => {
        gettates().then((res: any) => {
            setstateData(res?.result);
        }).catch((e: any) => console.error(e))

        jobtypes().then((res: any) => setjobtypesdata(res)).catch(e => console.error(e))
        ratePreference().then((res: any) => setrateType(res)).catch(e => console.error(e))
        JobProfessionLists().then((res: any) => {
            SetProfessions(res);
        }).catch((e: any) => console.error(e));
    }, [])

    const stateUpdated = (e: any) => {
        setValue('City', null)
        let stateID = (stateData?.find((val: any) => val.name == e.target.value)?.stateId || [])
        citiesOfState(stateID).then((resp: any) => {
            setcityData(resp?.result?.cities)
        }).catch((e: any) => console.error(e))
    }

    const professionChange = (e: any) => {
        setjoblicensesdata(AllProfessions?.find((val: any) => val.professionId == e.target.value)?.license || [])
    }

    const licenseChange = (e: any) => {
        setspecialtiesdata(joblicensesdata?.find((val: any) => val?.licenseId == e.target.value)?.specialties || [])
    }

    const ChangeRate = (e: any) => {
        setRate(e.target.value)
    }

    const FilePicker = (e: any) => {
        setFile(e.target.files[0]);
    }

    const removeFile = () => {
        setFile(null);
        if (fileInputRef.current) {
            (fileInputRef.current as HTMLInputElement).value = '';
        }
    }

    const onSubmit = (data: any) => {
        const rqstBody = {
            Subject: `Submitting profile for profession of ${AllProfessions?.find((val: any) => val.professionId == data?.profession)?.professionName}${data?.license ? (', licensed in ' + joblicensesdata?.find((val: any) => val?.licenseId == data?.license)?.licenseName) : ''}${data?.specialty ? (' and specialized in ' + specialtiesdata?.find((val: any) => val.specialtyId == data?.specialty)?.specialtyName) : ''}`,
            Body: `
            <p>Please find the attached resume for your reference.</p> ${data?.rateType ? '<p>Rate: ' + rateV + ' ' + data?.rateType + '</p>' : ''}<p>Profession of ${AllProfessions?.find((val: any) => val.professionId == data?.profession)?.professionName}${data?.license ? (', licensed in ' + joblicensesdata?.find((val: any) => val?.licenseId == data?.license)?.licenseName) : ''}${data?.specialty ? (' and specialized in ' + specialtiesdata?.find((val: any) => val.specialtyId == data?.specialty)?.specialtyName) : ''}.</p>
            <p>Regards</p>`,
        }

        const form = new FormData();
        form.append('Subject', rqstBody?.Subject);
        form.append('Body', rqstBody?.Body);
        // form.append('ToEmail', rqstBody?.ToEmail);
        form.append('File', file);
        setLoading(true)
        sendEmailData(form).then((result: any) => {
            setshowToast(true)
            if (result?.statusCode != 200) {
                setToastMsg(`Error in submitting details, please contact with system administrator`)
                setToasterType('error')
                return;
            }
            reset();
            setFile(null);
            setToastMsg(`${result.message}. We will get back to you with suitable job options`)
            setToasterType('success')
        }).catch(e => {
            setshowToast(true)
            setToastMsg(`Error in submitting details, please contact with system administrator`)
            setToasterType('error')
            console.error(e)
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Loader loading={isLoading} />
            {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
            <section className="helpFormSection py-5">
                <div className="container-fluid px-lg-5">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row gx-lg-5 gy-3">
                            <div className="col-12">
                                <h2 className="fw-medium mb-3">
                                    <span className="me-5">Don’t see what you are looking for?</span>{" "}
                                    <br className="d-lg-none" />Let us HELP you find it.
                                </h2>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlState">State *:</label>
                                <select className="form-select" id="ddlState" {...register("State", { required: true })} onChange={(e) => stateUpdated(e)} >
                                    <option value="" selected disabled>Select</option>
                                    {stateData?.map((data: any) => (
                                        <option key={'state' + data.name} value={data.name}>{data.name}</option>
                                    ))}
                                </select>
                                {errors.State && <span className="text-danger">State is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlCity">City *:</label>
                                <select className="form-select" id="ddlCity" {...register("City", { required: true })}>
                                    <option value="" selected disabled>Select</option>
                                    {cityData.map((data: any, index: number) => (
                                        <option key={'city' + index} value={data}>{data}</option>
                                    ))}
                                </select>
                                {errors.City && <span className="text-danger">City is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlSelectjobtype">
                                    Job Type *:
                                </label>
                                <select className="form-select" aria-label="Select job type" id="ddlSelectjobtype" {...register("jobType", { required: true })} >
                                    <option value="" selected disabled>Select</option>
                                    {jobtypesdata.map((data: any) => (
                                        <option key={'typeId' + data.typeId} value={data.name}>{data.name}</option>
                                    ))}
                                </select>
                                {errors.jobType && <span className="text-danger">Job type is required</span>}

                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlProfession">Profession *:</label>
                                <select className="form-select" id="ddlProfession" {...register("profession", { required: true })} onChange={(e) => professionChange(e)} >
                                    <option value="" selected disabled>Select</option>
                                    {AllProfessions.map((data: any) => (
                                        <option key={'profession' + data.professionId} value={data.professionId}>{data.professionName}</option>
                                    ))}
                                </select>
                                {errors.profession && <span className="text-danger">Profession is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlLicense">License :</label>
                                <select className="form-select" id="ddlLicense" {...register("license", { required: false })} onChange={(e) => licenseChange(e)}>
                                    <option value="" selected disabled>Select</option>
                                    {joblicensesdata.map((data: any) => (
                                        <option key={'licesnse' + data.licenseId} value={data.licenseId}>{data.licenseName}</option>
                                    ))}
                                </select>
                                {errors.license && <span className="text-danger">License is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlSpecialty">Specialty :</label>
                                <select className="form-select" id="ddlSpecialty" {...register("specialty", { required: false })}>
                                    <option value="" selected disabled>Select</option>
                                    {specialtiesdata.map((data: any) => (
                                        <option key={'speciality' + data.specialtyId} value={data.specialtyId}>{data.specialtyName}</option>
                                    ))}
                                </select>
                                {errors.specialty && <span className="text-danger">Specialty is required</span>}
                            </div>
                            <div className="col-md-4">
                                <label className="form-label" htmlFor="ddlRate">
                                    Rate:
                                </label>
                                <select className="form-select" id="ddlRateType" {...register("rateType", { required: false })}>
                                    <option value="" selected disabled>Select</option>
                                    {rateType.map((data: any) => (
                                        <option key={'rate' + data?.rateId} value={data?.description}>{data?.description}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4">
                                <label className="form-label d-inline-block text-center minimumRateLabel badge bg-secondary text-light py-2">
                                    <strong>Rate:</strong>{" "}
                                    <span className="fw-normal" id="selectedRangeValue">
                                        <strong>$</strong>{rateV}
                                    </span>
                                </label>
                                <div className="slidecontainer">
                                    <input type="range" min={1} max={4000}  {...register("rateValue", { required: false })} step={1} defaultValue={1} className="slider" id="myRange" onChange={(e: any) => ChangeRate(e)} />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <label className="form-label text-start">$1</label>
                                    <label className="form-label text-end">$4000</label>
                                </div>

                            </div>

                            <div className="col-md-4">
                                <label htmlFor="filePicker" className="form-label">
                                    Upload Resume
                                </label>
                                <input type="file" id="filePicker" className="form-control" onClick={(e: any) => e.target.value = null} onChange={e => FilePicker(e)} ref={fileInputRef} />
                                {file && (
                                    <div className="p-2 border-dark border my-2">
                                        <span>{file?.name}</span>
                                        <a href="javascript:void(0)" className="ms-2" onClick={e => setFile(null)} >
                                            <i className="sfa fa-trash-o"></i>
                                        </a>
                                    </div>
                                )}
                                {/* <div className="small">
                                   
                                </div> */}

                            </div>
                            <div className="col-12">
                                <hr className="border-top border-dark" />
                            </div>
                            <div className="col-md-3 offset-md-9 text-end">
                                <button className="btn btn-primary btn-lg d-block text-size-1dot5 px-4 py-3 rounded-0 w-100">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        </>
    )

}

export default GuidanceSection;