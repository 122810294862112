import './loader.css';
const Loader = ({ loading }: any) => {
    return (
        <div>
            {loading && (
                <div className="loader-container">
                    <div className="backdrop"></div>
                    <div className="loader"></div>
                </div>
            )}
        </div>
    )
}

export default Loader