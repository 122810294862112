import { useNavigate } from 'react-router-dom';
import BannerImage1 from '../assets/images/v-h-banner1.jpg';
import BannerImage2 from '../assets/images/v-h-banner2.jpg';
import BannerImage3 from '../assets/images/v-h-banner3.jpg';

import AdvantageDetail from '../components/homepageinfo/advantage-detail';
import ExpertiseDetail from '../components/homepageinfo/expertise-detail';
import GuidanceSection from '../components/homepageinfo/guidance-section';
import TestimonialSection from '../components/homepageinfo/testimonial-section';
import LocationSelect from '../components/sidefilters/location-select';
import ProfessionSpecialitySearch from '../components/common-views/preofession-speciality-search';
import { useState } from 'react';

const Home = () => {
    const navigate = useNavigate();
    const [loc, setLoc] = useState('');
    const [specIds, setSpecIds] = useState(null)

    const locationChange = (content: any) => {
        let locations = `${content}`;
        setLoc(locations)
    }

    const refreshContent = (paylaod: any) => {
        let specialtyIds = paylaod?.map((p: any) => p?.specialtyId);
        setSpecIds(specialtyIds)
    }

    const handleSearch = () => {
        navigate(`/jobs`, {
            replace: false,
            state: {
                loc,
                specIds
            }
        })
    }

    return (
        <>
            <section className="banner-carousel-section position-relative">
                <div className="carousel-overlay-container">
                    <div className="carousel-overlay">
                        <div className="p-3 p-lg-5">
                            <h1 className="text-center">
                                Discover Travel Nursing,
                                <br />
                                Allied &amp; Per Diem Jobs.
                            </h1>
                            <div className="banner-search-and-count-container">
                                <div className="banner-search-container px-4">
                                    <div className="row align-items-center">
                                        <div className="col-lg-5 col-xxl-4">
                                            <label className="form-label mb-0" htmlFor="ddlSpecialtyprofession">
                                                I am a Specialist in :
                                            </label>
                                        </div>
                                        <div className="col-lg-7 col-xxl-8">
                                            <ProfessionSpecialitySearch refreshContent={refreshContent} saveClickRequired={false} />
                                        </div>
                                    </div>
                                    <div className="row mt-3 align-items-center">
                                        <div className="col-lg-5 col-xxl-4">
                                            <label className="form-label mb-0" htmlFor="ddlPreferredLocations">
                                                Preferred Locations :
                                            </label>
                                        </div>
                                        <div className="col-lg-7 col-xxl-8">
                                            <LocationSelect locationChange={(e: any) => locationChange(e)} locl="''" />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-wrap mt-3">
                                        <div className="col-lg-5 col-xxl-4" />
                                        <div className="col-lg-7 col-xxl-8">
                                            <button className="btn btn-primary rounded-1 px-5 ms-2 homeSearchBtn" type='button' onClick={handleSearch} >
                                                Search Job
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="banner-available-container">
                                    <div className="text-size-1dot5">28,677</div>
                                    <div>
                                        Nursing and allied <br />
                                        jobs available now
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="carousel-banner"  className="carousel slide carousel-fade"  data-bs-ride="carousel" data-bs-interval={5000} data-bs-pause='false'>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                                <img src={BannerImage1} className="d-block w-100" alt="Slide 1" />
                            </div>
                            <div className="carousel-item">
                                <img src={BannerImage2} className="d-block w-100" alt="Slide 2" />
                            </div>
                            <div className="carousel-item">
                                <img src={BannerImage3} className="d-block w-100" alt="Slide 3" />
                            </div>
                            {/* Add more carousel items as needed */}
                        </div>
                    </div>
                </div>
            </section>
            {/*Carousel Section Ends*/}
            {/*Advantage section starts*/}

            <AdvantageDetail />

            {/*Advantage section Ends*/}
            {/*Factiltating Guidance Section starts*/}
            <section className="factilitatingGuidanceSection">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <h2 className="fw-semibold mb-3">Facilitating Guidance </h2>
                            <h3>On the Application and Renewal Process of Licenses.</h3>
                        </div>
                    </div>
                </div>
            </section>
            {/*Factiltating Guidance Section Ends*/}
            {/*Help Form Section starts*/}
            <GuidanceSection />
            <ExpertiseDetail />
            <TestimonialSection />
        </>

    )
}

export default Home;