import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Loader from '../components/loader/loader';
import Toast from '../components/toaster/toaster';
import { ContactUsSubmit } from '../utils/services/other-common.service';

const ContaceUs = () => {

  const [isLoading, setLoading] = useState(false);
  const [showToast, setshowToast] = useState(false);
  const [toasterMsg, setToastMsg] = useState('');
  const [toasterType, setToasterType] = useState('');
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const handleToastDetail = (toastContent: any) => {
    setshowToast(toastContent?.show)
    setToastMsg(toastContent?.msg)
    setToasterType(toastContent?.type)
  }

  const onSubmit = (data: any) => {
    setLoading(true)
    ContactUsSubmit(data).then((resp: any) => {
      if (resp?.statusCode != 200) {
        handleToastDetail({ show: true, msg: resp?.message, type: 'error' })
        return;
      }
      handleToastDetail({ show: true, msg: resp?.message, type: 'success' })
      reset();
    }).catch(err => {
      handleToastDetail({ show: true, msg: err?.response?.data?.message, type: 'error' })

    }).finally(() => {
      setLoading(false)
    })

  };

  return (
    <>
      <Loader loading={isLoading} />
      {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
      {/* <!--Banner--> */}
      <section className="innerPageBannerContainer contactusBanner">
        <div className="transparentBGBanner">
          <div className="bannerHeading mt-5">CONTACT US</div>
          <div className="bannerSubheading">Reach Out to Unlock Your Future <br /><span className='text-size-1dot25'>Connect with Our Talent Recruitment Experts Today!</span></div>
        </div>
      </section>
      {/* <!--Banner Ends-->  */}
      {/* <!--Section contact us--> */}
      <section className="contactUsContainer">
        <div id="main-contact-form-test" className="contact-form" >
          <div className="container">
            <div className="p-3 pt-0 text-center">
              <div className="sectionHead">
                <h2 className="fw-medium"><span className=" text-primary logo-bg-color">Let's make</span> something great together.</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 contactAddress">
                <div className="getInTouch pb-3">US</div>
                <div className="contactDetails"> 10 S Riverside Plaza <br />#875 Chicago, IL 60606</div>
                <hr />
                <div className="contactDetails"> 1750 E Golf Rd, <br />Suite 224, Schaumburg, IL 60173</div>
                <br /><br />
                {/* <div className="getInTouch pb-3">CANADA</div>
                <div className="contactDetails"> 450-111 Queen St E, <br />Toronto ON M5C 1S2, Canada</div>
                <br /><br />
                <div className="getInTouch pb-3">APAC</div>
                <div className="contactDetails"> M9-Ratchada.<br />Bangkok, 10310, THA </div> */}


              </div>
              <div className="col-lg-6 contactForm">
                <div className="row">
                  <div className="col-lg-12 pb-3">
                    <div className="getInTouch">Get in Touch</div>
                    <ul className="contactAddresList list-unstyled">
                      <li className="d-flex align-items-center">
                        <div className="contactIcon pr-3"> <i className="fa fa-phone"></i> </div>
                        <div className="contactDetails"> +1 (224) 328-7751</div>
                      </li>
                      <li className="d-flex align-items-center">
                        <div className="contactIcon pr-3"> <i className="fa fa-envelope"></i> </div>
                        <div className="contactDetails"> <a href="mailto:contact@voltousa.com">contact@voltousa.com</a> </div>
                      </li>
                    </ul>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-lg-12 pb-2">
                      <input type="text"   {...register("name", { required: true })} className="form-control" placeholder="Name"
                      />
                      {errors.name && <span className="text-danger">Name is required</span>}
                    </div>
                    <div className="col-lg-12 py-2">
                      <input type="text"  {...register("email", { required: true, pattern: /^\S+@\S+$/i })} className="form-control" placeholder="Email"
                      />
                      {errors.email && <span className="text-danger">Invalid email</span>}
                    </div>
                    <div className="col-lg-12 py-2">
                      <input type="tel" {...register("phone", { required: true, pattern: /^[0-9]{10}$/ })} className="form-control" placeholder="Phone"
                      />
                      {errors.phone && <span className="text-danger">Invalid phone number</span>}
                    </div>
                    <div className="col-lg-12 py-2">
                      <input type="text"  {...register("website", { required: false })} className="form-control" placeholder="Website"
                      />
                      {errors.website && <span className="text-danger">Invalid website</span>}
                    </div>
                    <div className="col-lg-12 py-2">
                      <textarea {...register("message", { required: true })} className="form-control" placeholder="Message"
                      ></textarea>
                      {errors.message && <span className="text-danger">Message is required</span>}
                    </div>
                    <div className="col-12 py-2">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>


        </div>


      </section>

      {/* <!--SEction Contact us ends-->  */}
    </>
  );
};
export default ContaceUs;
