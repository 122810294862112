export const API_END_POINTS = {
    BasePath: {
        Lookup: '/api/Lookup',
        Jobs: '/api/Job',
        Auth: '/api/Auth',
        Email: '/api/Email',
        QuickApply: '/api/QuickApply',
        Referral: '/api/Referral',
        Contact: '/api/Contact'
    },
    EndPoints: {
        Auth: {
            Login: '/login',
        },
        Lookup: {
            States: '/States',
            City: '/StateWithCities/',
            Schedules: '/schedules',
            Jobtypes: '/jobtypes',
            Professions: '/professions',
            Joblicense: '/joblicenses',
            Specialties: '/specialties',
            RateType: '/RateType',
            ShiftPrefernce: '/ShiftPreference',
            WorkSchedules: '/AssignmentWorkSchedule',
            Candidates: '/candidteList',
            QuickApply: '/QuickApplyList',
            ReferralCandidate: '/ReferralCandidteList'
        },
        Jobs: {
            Search: '/Search',
            Profession: '/Professions',
            SendEmail: '/SendEmailWithAttachment',
            SendEmailFileAttach: '/SendEmailAndSaveAttachment'

        },
    }
} 