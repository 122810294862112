import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './toast.css';

const Toaster = ({ message, type, duration = 5000, onClose }: any) => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setVisible(false);
            if (onClose) onClose();
        }, duration);

        return () => clearTimeout(timer);
    }, [duration, onClose]);

    const handleClose = () => {
        setVisible(false);
        if (onClose) onClose();
    };

    return (
        <div className={`toaster ${type} ${visible ? 'show' : ''}`}>
            <div className="toaster-message">{message}</div>
            <div className="close-button" onClick={handleClose}>✖</div>
        </div>
    );
};

const Toast = ({ message, type, duration, onClose }: any) => {
    return ReactDOM.createPortal(
        <Toaster message={message} type={type} duration={duration} onClose={onClose} />,
        document?.getElementById('toast-container') as any
    );
};

export default Toast;