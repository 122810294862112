import { NavLink } from "react-router-dom";
import { endPoints } from "../../utils/expoints.utils"


const BreadCrumbContent = ({ SectionName }: any) => {
    return (
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-end">
                {SectionName.breadCrumbs.map((value: any, i: number) => {
                    return (
                        <li className="breadcrumb-item active" key={i} >
                            {value?.path ?
                                (<NavLink to={value?.path} className="text-black">{value.name}
                                </NavLink>) :
                                value?.name
                            }
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}

export default BreadCrumbContent;