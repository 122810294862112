import axios from 'axios';
import { getAuthToken, removeStorage } from './localstorage.service';
import { useNavigate } from 'react-router-dom';

const axiosInstance = axios.create({
    baseURL: 'https://api.healthcare.voltoconsulting.com',
    // baseURL: 'http://167.172.158.209',
});


axiosInstance.interceptors.request.use(
    (config) => {
        // config.headers["Content-Type"] = "application/json, x-www-form-urlencoded";
        config.headers["Access-Control-Allow-Origin"] = "*";
        config.headers["Authorization"] = `Bearer ${getAuthToken()}`;

        if (config.url?.includes('SendEmailWithAttachment') || config.url?.includes('SendEmailAndSaveAttachment')) {
            config.headers['Content-Type'] = 'multipart/form-data';
        } else {
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response: any) => {
        // const navigate = useNavigate();
        if (response.statusCode === 401) {
            removeStorage();
            window.location.href = '/admin/login';
        }
        return response;
    },
    (error: any) => {
        if (error?.response?.status === 401) {
            removeStorage();
            window.location.href = '/admin/login';
        }
        return Promise.reject(error);
    }
);


export const axiosGet = (url: string, params?: {}) => {
    return axiosInstance.get(url, { params }).then((res) => {
        return res.data;
    });
};

export const axiosPost = (url: string, requestBody: any, params?: any) => {
    return axiosInstance.post(url, requestBody, { params }).then((res) => {
        return res?.data;
    });
};

export const axiosPut = (url: string, requestBody: any) => {
    return axiosInstance.put(url, requestBody).then((res) => {
        return res?.data;
    });
};

export const axiosDelete = (url: string) => {
    return axiosInstance.delete(url).then((res) => {
        return res?.data;
    });
};