import React from 'react'
import aboutImage from '../assets/images/aboutus-inner.jpg';
import ExpertiseDetail from '../components/homepageinfo/expertise-detail';
import AdvantageDetail from '../components/homepageinfo/advantage-detail';
import TestimonialSection from '../components/homepageinfo/testimonial-section';
const aboutUs = () => {
  return (
    <>
      {/* <!--Banner--> */}
      <section className="innerPageBannerContainer aboutusBanner">
        <div className="transparentBGBanner">
          <div className="bannerHeading mt-5">ABOUT US</div>
          <div className="bannerSubheading">Integrated Healthcare Staffing services<br />for Optimized Talent Acquisition</div>
        </div>
      </section>
      {/* <!--Banner Ends-->  */}
      <section className='py-5 aboutContentSection'>
        <div className="container-fluid px-lg-5">
          <div className="row gx-lg-5 gy-3">
            <div className="col-12">
              <h2 className="fw-medium">Volto HealthCare</h2>
            </div>
            <div className="col-lg-8 col-xxl-9 text-size-1dot1">
              Volto Healthcare is specializes in delivering comprehensive staffing solutions tailored specifically to the needs of healthcare clients. With our deep understanding of the industry and extensive network of talented professionals, we possess the expertise to match healthcare organizations with the right individuals who possess the required skills and experience. Whether our clients require temporary staff for short-term projects or seek long-term placements, Volto can provide a reliable and efficient solution. Our team of dedicated consultants diligently assesses client requirements, sources top-tier candidates, and facilitates seamless integration into their respective healthcare environments.
            </div>
            <div className="col-lg-4 col-xxl-3">
              <img src={aboutImage} className="img-fluid" alt="logo" />
            </div>
          </div>
        </div>
      </section>

      <ExpertiseDetail />
      {/*Advantage section starts*/}

      <AdvantageDetail />

      {/*Advantage section Ends*/}
      <TestimonialSection />
    </>
  )
};

export default aboutUs;