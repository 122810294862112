const ExpertiseDetail = () => {
    return (
        <section className="expertiseSetusApartSection py-5">
            <div className="container-fluid px-lg-5">
                <div className="row gx-lg-5 gy-3">
                    <div className="col-12">
                        <h2 className="fw-medium">Expertise That Sets Us Apart</h2>
                    </div>
                    <div className="col-lg-4 mt-3 expertiseColumn mb-3 mb-lg-0">
                        <h3 className="headingExpertise text-white">Nursing</h3>
                        <div className="text-white text-size-1dot2 lh-lg">
                            Volto Healthcare provides healthcare staffing services, to ensure
                            healthcare facilities have an adequate number of qualified nurses
                            for providing quality patient care.
                        </div>
                        <div className="expertiseBtnContainer">
                            <button className="btn btn-primary btn-blue rounded-0">
                                Learn more...
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-3 expertiseColumn mb-3 mb-lg-0">
                        <h3 className="headingExpertise text-white">Locum Tenens</h3>
                        <div className="text-white text-size-1dot2 lh-lg">
                            Volto Healthcare actively recruits Physicians and advanced practice
                            professionals according to specific requirements of the healthcare
                            institution.
                        </div>
                        <div className="expertiseBtnContainer">
                            <button className="btn btn-primary btn-blue rounded-0">
                                Learn more...
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 mt-3 expertiseColumn mb-3 mb-lg-0">
                        <h3 className="headingExpertise text-white">Non Clinical</h3>
                        <div className="text-white text-size-1dot2 lh-lg">
                            Non-clinical staff play essential roles in supporting the operations
                            and administration of healthcare facilities. Volto Healthcare
                            matches the skills, experience, and preferences of healthcare
                            facilities they partner with.
                        </div>
                        <div className="expertiseBtnContainer">
                            <button className="btn btn-primary btn-blue rounded-0">
                                Learn more...
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ExpertiseDetail