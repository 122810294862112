import icon_briefcasePlus from '../assets/images/icon_briefcasePlus.svg'
import icon_badge from '../assets/images/icon_badge.svg'
import icon_clock from '../assets/images/icon_clock.svg'
import icon_money from '../assets/images/icon_money.svg'
import BreadCrumbContent from '../components/common-views/breadcrumb'
import { endPoints } from '../utils/expoints.utils';
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from 'react'
import { JobProfessionLists, addResumeWithApply, applyForProfile, getJobDetail } from '../utils/services/jobs.service'
import { citiesOfState, gettates } from '../utils/services/lookup.service'
import Toast from '../components/toaster/toaster'
import Loader from '../components/loader/loader'
import { useParams } from 'react-router-dom'



const ApplyOnJob = () => {

    const { register, handleSubmit, control, formState: { errors }, reset, setValue } = useForm();
    const [AllProfessions, SetProfessions] = useState<any[]>([])
    const [stateData, setstateData] = useState<any[]>([]);
    const [cityData, setcityData] = useState([]);
    const [joblicensesdata, setjoblicensesdata] = useState<any[]>([])
    const [specialtiesdata, setspecialtiesdata] = useState<any[]>([])
    const [isLoading, setLoading] = useState(false)
    const [showToast, setshowToast] = useState(false);
    const [toasterMsg, setToastMsg] = useState('');
    const [toasterType, setToasterType] = useState('');
    const [JobDetails, setJobDetail] = useState<any>();
    const [file, setFile] = useState<any>(null);

    const fileInputRef = useRef(null);
    const params = useParams();

    const handleToastDetail = (toastContent: any) => {
        setshowToast(toastContent?.show)
        setToastMsg(toastContent?.msg)
        setToasterType(toastContent?.type)
    }

    useEffect(() => {
        if (Number(params.jobId) > 0) {
            setLoading(true)
            getJobDetail(params.jobId).then((response: any) => {
                if (response?.statusCode != 200) {
                    handleToastDetail({ show: true, msg: response?.message, type: 'error' })
                    return;
                }
                setJobDetail(response?.result);
            }).catch(e => {
                handleToastDetail({ show: true, msg: `Error in fetching job details`, type: 'error' })
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [params.jobId]);

    useEffect(() => {
        gettates().then((res: any) => {
            setstateData(res?.result);
        }).catch((e: any) => console.error(e))
        JobProfessionLists().then((res: any) => {
            SetProfessions(res);
        }).catch(e => console.error(e));
    }, []);

    const professionChange = (e: any) => {
        setjoblicensesdata(AllProfessions?.find((val: any) => val.professionId == e.target.value)?.license || [])
    }

    const licenseChange = (e: any) => {
        setspecialtiesdata(joblicensesdata?.find((val: any) => val?.licenseId == e.target.value)?.specialties || [])
    }

    const stateUpdated = (e: any) => {
        setValue('City', null)
        let stateID = (stateData?.find((val: any) => val.name == e.target.value)?.stateId || [])
        citiesOfState(stateID).then((resp: any) => {
            setcityData(resp?.result?.cities)
        }).catch((e: any) => console.error(e))
    }

    const FilePicker = (e: any) => {
        setFile(e.target.files[0]);
    }

    const removeFile = () => {
        setFile(null);
        if (fileInputRef.current) {
            (fileInputRef.current as HTMLInputElement).value = '';
        }
    }

    const onSubmit = (data: any) => {
        let payload = {
            quickApplyId: 0,
            jobId: JobDetails?.jobId,
            ...data,
            preferredDistanceMiles: Number(data?.preferredDistanceMiles),
            ReferredBy: 'recruiter',
            professionId: Number(data?.professionId),
            licenseId: Number(data?.licenseId),
            specialtyId: Number(data?.specialtyId),
        }

        setLoading(true);

        // if (file) {
        let professionName = AllProfessions?.find((val: any) => val.professionId == data?.professionId)?.professionName;
        let licenseName = joblicensesdata?.find((val: any) => val?.licenseId == data?.licenseId)?.licenseName;
        let specialtyName = specialtiesdata?.find((val: any) => val.specialtyId == data?.specialtyId)?.specialtyName;
        let queryParamBody = {
            FromEmail: data?.emailAddress,
            Subject: `New job applicaiton on job - ${JobDetails?.jobTitle}`,
            Body: `
                <p>Please find the attached resume for your reference and details for apply</p>
                <p><strong>Applying for role of ${JobDetails?.jobTitle}</strong><p>
                <p>profession of ${professionName}, licensed in ${licenseName} and specialized in ${data?.specialtyId ? specialtyName : ('all ' + licenseName + ' field')}</p>
            <p>Regards, </p>
        `
        }
        let formData = new FormData();
        formData.append('File', file)
        addResumeWithApply(formData, queryParamBody).then((resp: any) => {
            if (resp?.statusCode == 200) {
                payload = {
                    ...payload,
                    resumeFileName: resp?.result?.fileName,
                    resumeFilePath: resp?.result?.filePath
                }
                continueWithSave(payload)
            }
        }).catch((err: any) => {
            console.error(err)
            setLoading(false)
        })
        return;
    }

    const continueWithSave = (payload: any) => {
        applyForProfile(payload).then((response: any) => {
            if (response?.statusCode != 200) {
                handleToastDetail({ show: true, msg: response?.message, type: 'error' })
                return;
            }
            handleToastDetail({ show: true, msg: 'Job application submitted successfully', type: 'success' });
            reset();
        }).catch(e => {
            handleToastDetail({ show: true, msg: `Error in fetching job details`, type: 'error' });
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <Loader loading={isLoading} />
            {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
            {/* <section className="explore-hero-area">
                <div className="">Locum Gastroenterology Physician Job in Columbia, SC.</div>
            </section> */}
            <section className="explore-hero-area">
                <div className="">
                    Explore nationwide career opportunities in the healthcare sector to
                    advance your professional journey.
                </div>
            </section>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Job Detail </h4>
                    </div>
                    <div className="col-lg-6 text-lg-end">
                        <BreadCrumbContent SectionName={endPoints.APPLYONJOB} />
                    </div>
                </div>
            </div>

            <section className="job-detail-section">
                <div className="job-detail-brief">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-6 d-none">
                                <span className="badge bg-secondary bg-opacity-25 text-uppercase rounded-4 text-black px-3">Travel</span>
                            </div>
                            {
                                JobDetails?.immediateJoining && (
                                    <div className="col-12 text-end">
                                        <span className="text-ornage">
                                            <i className="fa fa-hourglass-half"></i> Immediate Offer
                                        </span>
                                    </div>
                                )
                            }

                            <div className="col-12 mt-3">
                                <div><strong>{JobDetails?.jobTitle}</strong></div>
                            </div>

                            <div className="col-12 mt-1">
                                <div><strong>{JobDetails?.cityName}, {JobDetails?.stateName}</strong></div>
                                <div className="text-muted small">{JobDetails?.profession?.professionName}</div>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_briefcasePlus} className="result-item-icon" alt="icon1" /> <span
                                    className="text-size-0dot85 lh-1">{JobDetails?.license?.licenseName || 'N/a'}</span>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_badge} className="result-item-icon" alt="icon2" /> <span
                                    className="text-size-0dot85">{JobDetails?.specialty?.specialtyName}</span>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_clock} className="result-item-icon" alt="icon3" /> <span
                                    className="text-size-0dot85">{JobDetails?.assignmentDuration} {JobDetails?.assignmentWorkSchedule?.name}</span>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_money} className="result-item-icon" alt="icon4" /> <span
                                    className="text-size-0dot85">${JobDetails?.rateValue?.toFixed(2)}/{JobDetails?.rate?.rateType}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="job-details-container py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="job-details-wrapper">
                                    <div className="job-detail-title mb-1">Your Info</div>
                                    <div className="text-black-50 mb-4">Please fill your information form to apply</div>
                                    <form onSubmit={handleSubmit(onSubmit)}>

                                        <div className="job-detail-description">
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" htmlFor="txtFirstName">First Name *:</label>
                                                    <input type="text" className="form-control" id="txtFirstName" {...register("firstName", { required: true })} />
                                                    {errors.firstName && <span className="text-danger">First name is required.</span>}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" htmlFor="txtLastName">Last Name *:</label>
                                                    <input type="text" className="form-control" id="txtLastName" {...register("lastName", { required: true })} />
                                                    {errors.lastName && <span className="text-danger">Last name is required.</span>}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" htmlFor="txtEmailAdd">Email Address *:</label>
                                                    <input type="email" className="form-control" id="txtEmailAdd" {...register("emailAddress", { required: true, pattern: /^\S+@\S+$/i })} />
                                                    {errors.emailAddress && <span className="text-danger">Email address is required.</span>}
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" htmlFor="txtPhoneNumber">Phone Number :</label>
                                                    <input type="tel" className="form-control" id="txtPhoneNumber" {...register("phoneNumber", { required: false, minLength: 10 })} />
                                                    {errors.phoneNumber && <span className="text-danger">Phone number is invalid.</span>}
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label" htmlFor="ddlProfession">Profession *:</label>
                                                    <select className="form-select" id="ddlProfession" {...register("professionId", { required: true })} onChange={(e) => professionChange(e)} >
                                                        <option value="" selected disabled>Select</option>
                                                        {AllProfessions.map((data: any) => (
                                                            <option key={'profession' + data.professionId} value={data.professionId}>{data.professionName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.professionId && <span className="text-danger">Profession is required</span>}
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label" htmlFor="ddlLicense">License *:</label>
                                                    <select className="form-select" id="ddlLicense" {...register("licenseId", { required: true })} onChange={(e) => licenseChange(e)}>
                                                        <option value="" selected disabled>Select</option>
                                                        {joblicensesdata.map((data: any) => (
                                                            <option key={'licesnse' + data.licenseId} value={data.licenseId}>{data.licenseName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.licenseId && <span className="text-danger">License is required</span>}
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label" htmlFor="ddlSpecialty">Specialty :</label>
                                                    <select className="form-select" id="ddlSpecialty" {...register("specialtyId", { required: false })}>
                                                        <option value="" selected disabled>Select</option>
                                                        {specialtiesdata.map((data: any) => (
                                                            <option key={'speciality' + data.specialtyId} value={data.specialtyId}>{data.specialtyName}</option>
                                                        ))}
                                                    </select>
                                                    {errors.specialtyId && <span className="text-danger">Specialty is required</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-top border-dark-subtle my-4" />


                                        <div className="job-detail-title mb-1">Location Info</div>
                                        <div className="text-black-50 mb-4">Choosing desired states or cities achieves better
                                            job-matching results. (optional)</div>
                                        <div className="job-detail-description">
                                            <div className="row">
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label" htmlFor="ddlState">State:</label>
                                                    <select className="form-select" id="ddlState" {...register("stateName", { required: false })} onChange={(e) => stateUpdated(e)} >
                                                        <option value="" selected disabled>Select</option>
                                                        {stateData?.map((data: any) => (
                                                            <option key={'state' + data.name} value={data.name}>{data.name}</option>
                                                        ))}
                                                    </select>
                                                    {errors.State && <span className="text-danger">State is required</span>}
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label" htmlFor="ddlCity">City:</label>
                                                    <select className="form-select" id="ddlCity" {...register("cityName", { required: false })}>
                                                        <option value="" selected disabled>Select</option>
                                                        {cityData.map((data: any, index: number) => (
                                                            <option key={'city' + index} value={data}>{data}</option>
                                                        ))}
                                                    </select>
                                                    {errors.City && <span className="text-danger">City is required</span>}
                                                </div>
                                                <div className="col-md-4 mb-3">
                                                    <label className="form-label" htmlFor="txtPreferredDistance">Preferred Distance
                                                        (miles):</label>
                                                    <input type="number" className="form-control" id="txtPreferredDistance" {...register("preferredDistanceMiles", { required: false, min: 1 })} />
                                                </div>
                                            </div>
                                        </div>

                                        <hr className="border-top border-dark-subtle my-4" />

                                        <div className="job-detail-title mb-1">Recruiter Info</div>
                                        <div className="text-black-50 mb-4">If you have been in touch with a recruiter, select
                                            below (optional)</div>
                                        <div className="job-detail-description">
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" htmlFor="txtFirstName">Recruiter's Name:</label>
                                                    <input type="text" className="form-control" id="txtFirstName" {...register("recruiterName", { required: false })} />
                                                </div>
                                                <div className="col-md-6 mb-3">
                                                    <label className="form-label" htmlFor="txtLastName">Recruiter's Phone Number:</label>
                                                    <input type="number" className="form-control" id="txtLastName" {...register("recruiterPhoneNumber", { required: false, minLength: 10 })} />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="border-top border-dark-subtle my-4" />
                                        <div className="job-detail-title mb-4">Upload Resume</div>

                                        <div className="job-detail-description">
                                            <div className="row">
                                                <div className="col-md-6 mb-3">
                                                    {/* <input type="file" className="form-control" id="txtUploadResume" aria-label='Resume' /> */}
                                                    <input type="file" id="filePicker" ref={fileInputRef} className="form-control" onClick={(e: any) => e.target.value = null} onChange={e => FilePicker(e)} />
                                                    {file && (
                                                        <div className="p-2 border-dark border my-2">
                                                            <span>{file?.name}</span>
                                                            <a href="javascript:void(0)" className="ms-2" onClick={e => removeFile()} >
                                                                <i className="fa fa-trash-o"></i>
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div>

                                        <hr className="border-top border-dark-subtle my-4" />

                                        <div className="mt-3 text-center">
                                            <button className="btn btn-dark ms-2 rounded-1 px-5 btn-lg">Apply for this Job</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ApplyOnJob;