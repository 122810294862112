import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { addReferralForJob, applyForProfile, getJobDetail } from "../../utils/services/jobs.service";
import Loader from "../loader/loader";
import Toast from "../toaster/toaster";
import { useForm } from "react-hook-form";

const ReferralSubmitSection = ({ modalOpen, handleClose, confirmationCloseHandle, JobId }: any) => {


    const [isLoading, setLoading] = useState(false);
    const [showToast, setshowToast] = useState(false);
    const [toasterMsg, setToastMsg] = useState('');
    const [toasterType, setToasterType] = useState('');
    const [jobDetail, setJobDetail] = useState<any>();

    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const handleToastDetail = (toastContent: any) => {
        setshowToast(toastContent?.show)
        setToastMsg(toastContent?.msg)
        setToasterType(toastContent?.type)
    }

    useEffect(() => {
        if (Number(JobId) > 0 && modalOpen) {
            setLoading(true)
            getJobDetail(JobId).then((response: any) => {
                if (response?.statusCode != 200) {
                    handleToastDetail({ show: true, msg: response?.message, type: 'error' })
                    return;
                }
                setJobDetail(response?.result);
            }).catch(e => {
                handleToastDetail({ show: true, msg: `Error in fetching job details`, type: 'error' })
                // handleClose();
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [modalOpen]);

    const onSubmit = (data: any) => {
        let payload = {
            "referralId": 0,
            "jobId": JobId,
            ...data
        }

        setLoading(true)
        addReferralForJob(payload).then((response: any) => {
            if (response?.statusCode != 200) {
                handleToastDetail({ show: true, msg: response?.message, type: 'error' })
                return;
            }
            handleToastDetail({ show: true, msg: 'Referral added successfully', type: 'success' });
            reset();
            handleClose()
        }).catch(e => {
            handleToastDetail({ show: true, msg: `Error in submitting reference`, type: 'error' });
        }).finally(() => {
            setLoading(false)
        })
    };

    return (
        <>
            <Loader loading={isLoading} />
            {showToast && <Toast message={toasterMsg} type={toasterType} onClose={() => setshowToast(false)} />}
            <Modal show={modalOpen} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Referral Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid bg-light px-4 py-3">
                        <div className="row justify-content-between align-items-center">

                            <div className="col-12">
                                <div><strong>{jobDetail?.cityName}, {jobDetail?.stateName} | Job ID #{JobId} | {jobDetail?.jobTitle}</strong></div>
                                <div className="small  mt-2">
                                    <span
                                        className="badge text-uppercase rounded-4 text-black px-3 py-2 bg-dark-subtle d-none">Travel</span>
                                    <span className="mx-3">{jobDetail?.profession?.professionName}/{jobDetail?.license?.licenseName}</span>
                                    {
                                        jobDetail?.immediateJoining && (
                                            <span className="text-ornage">
                                                <i className="fa fa-hourglass-half"></i> Immediate Offer
                                            </span>
                                        )
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="section-title">Your Info</div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtFirstName">First Name *:</label>
                                        <input type="text" className="form-control" id="txtFirstName"  {...register("firstName", { required: true })} />
                                        {errors.firstName && <span className="text-danger">First name is required.</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtLastName">Last Name *:</label>
                                        <input type="text" className="form-control" id="txtLastName" {...register("lastName", { required: true })} />
                                        {errors.lastName && <span className="text-danger">Last name is required.</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtEmailAdd">Email Address *:</label>
                                        <input type="email" className="form-control" id="txtEmailAdd" {...register("emailAddress", { required: true, pattern: /^\S+@\S+$/i })} />
                                        {errors.emailAddress && <span className="text-danger">Please enter a valid email address.</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtPhoneNumber">Phone Number :</label>
                                        <input type="number" className="form-control" id="txtPhoneNumber"  {...register("phoneNumber", { required: false, minLength: 10 })} />
                                        {errors.phoneNumber && <span className="text-danger">Phone number is invalid.</span>}
                                    </div>

                                    <div className="col-12">
                                        <div className="section-title">Your referral's info</div>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtReferralFirstName">Referral's First Name *:</label>
                                        <input type="text" className="form-control" id="txtReferralFirstName"  {...register("referralFirstName", { required: true })} />
                                        {errors.referralFirstName && <span className="text-danger">Referral's First name is required.</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtReferralLastName">Referral's Last Name *:</label>
                                        <input type="text" className="form-control" id="txtReferralLastName"  {...register("referralLastName", { required: true })} />
                                        {errors.referralLastName && <span className="text-danger">Referral's Last name is required.</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtReferralEmailAdd">Referral's Email Address *:</label>
                                        <input type="email" className="form-control" id="txtReferralEmailAdd"  {...register("referralEmailAddress", { required: true, pattern: /^\S+@\S+$/i })} />
                                        {errors.referralEmailAddress && <span className="text-danger">Please enter a valid email address.</span>}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label" htmlFor="txtReferralPhoneNumber">Referral's Phone Number :</label>
                                        <input type="number" className="form-control" id="txtReferralPhoneNumber"  {...register("referralPhoneNumber", { required: false, minLength: 10 })} />
                                        {errors.referralPhoneNumber && <span className="text-danger">Phone number is invalid.</span>}
                                    </div>

                                    <div className="col-12 text-end">
                                        <button type="button" onClick={e => (reset(), confirmationCloseHandle())} className="btn btn-secondary mx-2">Close</button>
                                        <button type="submit" className="btn btn-primary">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default ReferralSubmitSection;