import icondetail_location from '../assets/images/icondetail_location.svg';
import icondetail_schedule from '../assets/images/icondetail_schedule.svg';
import icondetail_type from '../assets/images/icondetail_type.svg';
import icondetail_opening from '../assets/images/icondetail_opening.svg';
import icondetail_profession from '../assets/images/icondetail_profession.svg';
import icondetail_startdate from '../assets/images/icondetail_startdate.svg';
import icondetail_specialty from '../assets/images/icondetail_specialty.svg';
import icondetail_experience from '../assets/images/icondetail_experience.svg';
import icondetail_rate from '../assets/images/icondetail_rate.svg';
import icondetail_facilityinfo from '../assets/images/icondetail_facilityinfo.svg';
import icondetail_assignment from '../assets/images/icondetail_assignment.svg';
import icondetail_immediatejoining from '../assets/images/icondetail_immediatejoining.svg';
import icon_briefcasePlus from '../assets/images/icon_briefcasePlus.svg'
import icon_badge from '../assets/images/icon_badge.svg'
import icon_clock from '../assets/images/icon_clock.svg'
import icon_money from '../assets/images/icon_money.svg'
import BreadCrumbContent from '../components/common-views/breadcrumb';
import { endPoints } from '../utils/expoints.utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ReferralSubmitSection from '../components/common-views/referral-submit';
import { getJobDetail } from '../utils/services/jobs.service';
const JobDetailView = () => {
    const navigate = useNavigate();
    const params = useParams()

    const [JobDetails, setJobDetail] = useState<any>({});
    const [modalOpen, setOpneModal] = useState(false)

    useEffect(() => {
        if (Number(params.jobId) > 0) {
            getJobDetail(params.jobId).then((result: any) => {
                setJobDetail(result?.result)
            }).catch(e => {
                console.error(e)
            })
        }
    }, [params.jobId])

    const handleRefer = () => {
        setOpneModal(true);
    }

    const handleClose = () => {
        setOpneModal(false)
    }

    const confirmationCloseHandle = () => {
        handleClose()
    }

    return (
        <>
            {/* <section className="explore-hero-area">
                <div className="">Locum Gastroenterology Physician Job in Columbia, SC.</div>
            </section> */}
            <section className="explore-hero-area">
                <div className="">
                    Explore nationwide career opportunities in the healthcare sector to
                    advance your professional journey.
                </div>
            </section>

            <div className="container mt-3">
                <div className="row">
                    <div className="col-lg-6">
                        <h4>Job Detail </h4>
                    </div>
                    <div className="col-lg-6 text-lg-end">
                        <BreadCrumbContent SectionName={endPoints.SPECIFICJOB} />
                    </div>
                </div>
            </div>

            <section className="job-detail-section">
                <div className="job-detail-brief">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-6 d-none">
                                <span className="badge bg-secondary bg-opacity-25 text-uppercase rounded-4 text-black px-3">Travel</span>
                            </div>
                            {
                                JobDetails?.immediateJoining && (
                                    <div className="col-12 text-end">
                                        <span className="text-ornage">
                                            <i className="fa fa-hourglass-half"></i> Immediate Offer
                                        </span>
                                    </div>
                                )
                            }
                            <div className="col-12 mt-3">
                                <div><strong>{JobDetails?.jobTitle}</strong></div>
                            </div>

                            <div className="col-12 mt-1">
                                <div><strong>{JobDetails?.cityName}, {JobDetails?.stateName}</strong></div>
                                <div className="text-muted small">{JobDetails?.profession?.professionName}</div>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_briefcasePlus} className="result-item-icon" alt="icon1" /> <span
                                    className="text-size-0dot85 lh-1">{JobDetails?.license?.licenseName || 'N/a'}</span>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_badge} className="result-item-icon" alt="icon2" /> <span
                                    className="text-size-0dot85">{JobDetails?.specialty?.specialtyName}</span>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_clock} className="result-item-icon" alt="icon3" /> <span
                                    className="text-size-0dot85">{JobDetails?.assignmentDuration} {JobDetails?.assignmentWorkSchedule?.name}</span>
                            </div>
                            <div className="col-md-6 col-lg-3 mt-2">
                                <img src={icon_money} className="result-item-icon" alt="icon4" /> <span
                                    className="text-size-0dot85">${JobDetails?.rateValue?.toFixed(2)}/{JobDetails?.rate?.rateType}</span>
                            </div>
                            <div className="col-12 mt-3">
                                <button className="btn btn-outline-primary px-4 rounded-1" onClick={() => handleRefer()}>Refer</button>
                                <button className="btn btn-dark ms-2 rounded-1" onClick={() => navigate(`/apply/job/${params.jobId}`)}>Apply for this job</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="job-details-container py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="job-details-wrapper">
                                    <div className="job-detail-title">Job Description</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <img src={icondetail_location} className="img-iconjobdetail" />
                                                <strong>Location : </strong>{JobDetails?.cityName}, {JobDetails?.stateName}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <img src={icondetail_schedule} className="img-iconjobdetail" />
                                                <strong>Schedule : </strong>{JobDetails?.schedule}</div>
                                            <div className="col-md-6 mb-3">
                                                <img src={icondetail_type} className="img-iconjobdetail" />
                                                <strong>Type : </strong>RN</div>
                                            <div className="col-md-6 mb-3">
                                                <img src={icondetail_opening} className="img-iconjobdetail" />
                                                <strong>Openings : </strong>{JobDetails?.openings}</div>
                                            <div className="col-md-6 mb-3">
                                                <img src={icondetail_profession} className="img-iconjobdetail" />
                                                <strong>Profession : </strong>{JobDetails?.profession?.professionName}
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <img src={icondetail_startdate} className="img-iconjobdetail" />
                                                <strong>Start Date
                                                    : </strong>{JobDetails?.startDate}</div>
                                            <div className="col-md-6 mb-3"><img src={icondetail_specialty}
                                                className="img-iconjobdetail" /> <strong>Specialty : </strong>{JobDetails?.specialty?.specialtyName}</div>
                                            <div className="col-md-6 mb-3"><img src={icondetail_experience}
                                                className="img-iconjobdetail" /> <strong>Experience : </strong>{JobDetails?.experience} year
                                            </div>
                                            <div className="col-md-6 mb-3"><img src={icondetail_rate}
                                                className="img-iconjobdetail" /> <strong>Rate : </strong>${JobDetails?.rateValue?.toFixed(2)}/{JobDetails?.rate?.rateType}</div>
                                            {/* <div className="col-md-6 mb-3"><img src={icondetail_facilityinfo}
                                                className="img-iconjobdetail" /><strong> Facility Info : </strong>Log in
                                                to view details</div> */}
                                            <div className="col-md-6 mb-3"><img src={icondetail_assignment}
                                                className="img-iconjobdetail" /> <strong>Assignment Length : </strong>{JobDetails?.assignmentDuration}
                                                {JobDetails?.assignmentWorkSchedule?.name}</div>
                                            <div className="col-md-6 mb-3"><img src={icondetail_immediatejoining}
                                                className="img-iconjobdetail" /> <strong>Immediate Joining :</strong>
                                                {JobDetails?.immediateJoining ? 'Yes' : 'No'}</div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="job-detail-title">Job Responsibilities</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div dangerouslySetInnerHTML={{ __html: JobDetails?.responsibilities }} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="job-detail-title">Working Hours</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {JobDetails?.schedule}
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="job-detail-title">Qualifications</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div dangerouslySetInnerHTML={{ __html: JobDetails?.qualifications }} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="job-detail-title">Skills</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div dangerouslySetInnerHTML={{ __html: JobDetails?.skills }} />

                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="job-detail-title">Education Requirements</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div dangerouslySetInnerHTML={{ __html: JobDetails?.educationRequirements }} />
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="job-detail-title">Experience Requirements</div>
                                    <div className="job-detail-description">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div dangerouslySetInnerHTML={{ __html: JobDetails?.experienceRequirements }} />

                                            </div>
                                        </div>
                                    </div>
                                    <hr className="border-top border-dark" />
                                    <div className="mt-3">
                                        <button className="btn btn-outline-primary px-4 rounded-1" onClick={() => handleRefer()} >Refer</button>
                                        <button className="btn btn-dark ms-2 rounded-1" onClick={() => navigate(`/apply/job/${params.jobId}`)} >Apply for this job</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <ReferralSubmitSection modalOpen={modalOpen} handleClose={handleClose} JobId={params.jobId} confirmationCloseHandle={confirmationCloseHandle} />

        </>
    )
}

export default JobDetailView;