
export const getAuthToken = () => {
    return window.localStorage.getItem('authToken') || null;
}

export const setAuthToken = (tokenValue: any) => {
    window.localStorage.setItem('authToken', tokenValue);
}

export const removeStorage = () => {
    window.localStorage.clear();
}
