export const endPoints = {
    HOME: {
        path: '/',
        breadCrumbs: []
    },
    JOBLIST: {
        path: '/jobs',
        breadCrumbs: [{
            name: 'Home',
            path: '/'
        }, {
            name: 'Job List',
            path: null
        }]
    },
    SPECIFICJOB: {
        path: '/jobs/:jobId',
        breadCrumbs: [{
            name: 'Home',
            path: '/'
        }, {
            name: 'Job List',
            path: '/jobs'
        }, {
            name: 'Job Detail',
            path: null
        }]
    },
    APPLYONJOB: {
        path: '/apply/job/:jobId',
        breadCrumbs: [{
            name: 'Home',
            path: '/'
        }, {
            name: 'Job List',
            path: '/jobs'
        }, {
            name: 'Job Detail',
            path: null
        }]
    },
    ADMINLOGIN: {
        path: '/admin/login',
    },
    ADMINJOBLISITNG: {
        path: '/admin/listings',
        breadCrumbs: [{
            name: 'Home',
            path: null
        }]
    },
    CREATEJOBPOSTING: {
        path: '/admin/create-job',
        breadCrumbs: [{
            name: 'Home',
            path: '/admin/listings'
        }, {
            name: 'Create New Job',
            path: null
        }]
    },
    EDITJOBPOSTING: {
        path: '/admin/edit-job/:jobId',
        breadCrumbs: [{
            name: 'Home',
            path: '/admin/listings'
        }, {
            name: 'Edit Job',
            path: null
        }]
    },
    CONTACTUS: {
        path: '/contact-us',
        breadCrumbs: [{
            name: 'Home',
            path: '/'
        }, {
            name: 'Contact us',
            path: null
        }]
    },
    ABOUTUS: {
        path: '/about-us',
        breadCrumbs: [{
            name: 'Home',
            path: '/'
        }, {
            name: 'About us',
            path: null
        }]
    }
}